import React from 'react';
import ReactPlayer from 'react-player';

import ContentWidget from '../../../components/ContentWidget';
import videoExplainerPlaceholder from '../../../assets/video-placeholder.png';

import DynamicResultHeader from './DynamicResultHeader';
import DynamicBreakdown from '../../../components/DynamicBreakdown';
import WhatsNext from './WhatsNext';
import UnderstandYourDynamic from './UnderstandYourDynamic';
import RecommendedCourses from './RecommendedCourses';
import RateYourDynamic from './RateYourDynamic';
import TransformationPanel from './TransformationPanel';

const SelectedDynamic = ({ dynamic }) => {
  if (!dynamic) {
    return null;
  }

  return (
    <div className="bg-white mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 items-center lg:mx-0 lg:max-w-none lg:grid-cols-1 mb-4">
      <DynamicResultHeader dynamic={dynamic} />
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center align-top flex">
        <div className="flex">
          <DynamicBreakdown dynamic={dynamic} />
        </div>
        <div className="flex">
          <ContentWidget title="What is the Enlightener Archetype?">
            <span className="text-md mt-2">
              Watch the video below to discover more about the Archetype of your
              Communicator Dynamic®
            </span>
            <span className="mt-4 text-md font-sans">
              <div className="mb-1">
                <u>Meaning:</u>
              </div>
              {dynamic.archetypeDescription}
              {dynamic.archetypeVideoUrl}
            </span>
            {dynamic.archetypeVideoUrl ? (
              <ReactPlayer url={dynamic.archetypeVideoUrl} />
            ) : (
              <img
                src={videoExplainerPlaceholder}
                className="mt-6 opacity-50 flex"
                alt=""
              />
            )}
          </ContentWidget>
        </div>
      </div>
      <div className="w-full">
        <WhatsNext dynamic={dynamic} />
      </div>
      <div className="w-full">
        <UnderstandYourDynamic dynamic={dynamic} />
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 items-center min-w-full">
        <RecommendedCourses />
        <div className="col-span-2">
          <RateYourDynamic dynamic={dynamic} />
        </div>
      </div>
      <div className="w-full">
        <TransformationPanel dynamic={dynamic} />
      </div>
    </div>
  );
};

export default SelectedDynamic;
