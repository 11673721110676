import React from 'react';
import useAssessment from '../../../../hooks/assessment';
import ContentWidget from '../../../ContentWidget';
import Button from '../../../Button';

const With = () => {
  const { setWith, nextStep, context } = useAssessment();

  return (
    <div className="mx-auto max-w-lg">
      <ContentWidget title="Who are you speaking to?">
        <div className="mt-3 flex w-full justify-center">
          <input
            type="text"
            defaultValue={context?.with}
            onChange={e => setWith(e.target.value)}
            name="with"
            id="with"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
            placeholder="i.e. Jane"
          />
        </div>
        <div className="flex w-full justify-center">
          <Button
            onClick={() => {
              setWith('Bob');
              nextStep();
            }}
            text="CONTINUE"
          />
        </div>
      </ContentWidget>
    </div>
  );
};

export default With;
