import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { subscribeActionMiddleware } from 'redux-subscribe-action';
import {
  DynamicsReducer,
  UserReducer,
  TeamsReducer,
  ConversationsReducer,
  AssessmentReducer
} from '../reducers';
import subscribe from './../subscribers';

const storeReducers = {
  Dynamics: DynamicsReducer,
  User: UserReducer,
  Teams: TeamsReducer,
  Conversations: ConversationsReducer,
  Assessment: AssessmentReducer
};

const store = createStore(
  combineReducers(storeReducers),
  composeWithDevTools(applyMiddleware(thunk, subscribeActionMiddleware))
);

subscribe();
store.loaded = true;

export default store;
