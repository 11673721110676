import React from 'react';
import ContentWidget from '../../../components/ContentWidget';
import Button from '../../../components/Button';
import useDynamics from '../../../hooks/dynamics';
import { friendlyTimestamp } from '../../../utils/Timestamp';

const RecentTests = props => {
  const { dynamics } = useDynamics();

  return (
    <ContentWidget
      title="Your Most Recent Tests"
      height={430}
      headerContent={
        <div className="flex flex-col items-end border-1 lg:ml-10 lg:flex-row">
          <div className="mx-2 mt-3 lg:mt-0">
            <Button path="/mini-test" outline text="Take a Mini-Test" />
          </div>
          <div className="mx-2 mt-1 lg:mt-0">
            <Button path="/full-test" green text="Start a Full Test" />
          </div>
        </div>
      }>
      <div className="min-w-full px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Date/Time
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Test Type
                    </th>
                    <th
                      scope="col"
                      className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Subject
                    </th>
                    <th
                      scope="col"
                      className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      With
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Dynamic
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {dynamics.map((dynamic, index) => (
                    <tr
                      key={index}
                      className="group cursor-pointer hover:bg-cd-green m-1 rounded px-4">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-600 sm:pl-0 group-hover:text-white">
                        {dynamic.archetype}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 group-hover:text-white">
                        {dynamic.type}
                      </td>
                      <td className="hidden lg:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600 group-hover:text-white">
                        {dynamic.subject}
                      </td>
                      <td className="hidden lg:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600 group-hover:text-white">
                        {dynamic.with}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 group-hover:text-white">
                        {friendlyTimestamp(dynamic.updatedAt, true)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ContentWidget>
  );
};

export default RecentTests;
