import validator from 'validator';

const isValidEmail = email => validator.isEmail(email);

const isValidNINumber = text => {
  const pattern = /^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]{1}\s*$/;
  const regex = new RegExp(pattern);
  if (regex.test(text)) {
    return true;
  }
  return { error: 'Invalid NI number' };
};

/*
  https://regex101.com/r/0bH043/1
  This regex matches only when all the following are true:

  password must contain 1 number (0-9)
  password must contain 1 uppercase letters
  password must contain 1 lowercase letters
  password must contain 1 non-alpha numeric number...
*/
const isValidPassword = password => {
  const pattern = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;
  const regex = new RegExp(pattern);
  return regex.test(password);
};

const isValidTextEntry = text => validator.isAlphanumeric(text);

const isValidFirstOrLastName = text => {
  return validator.isAscii(text) && text.trim && text.trim().length >= 1;
};

const isValidTextInputEntry = (element, value) => {
  let isValid = true;

  // validation: date, email, password, number
  if (!element.required && (!value || value.length === 0)) {
    return isValid;
  } else if (element.required && (!value || value.length === 0)) {
    return { error: `${element.label} is required` };
  } else if (element.validation === 'email') {
    if (!isValidEmail(value)) {
      return { error: 'A valid email is required' };
    }
  } else if (element.validation === 'ninumber') {
    isValid = isValidNINumber(value);
  } else if (element.validation === 'date') {
    isValid = isValidDate(value, element);
  } else if (element.validation === 'password') {
    isValid = isValidPassword(value);
  } else if (element.validation === 'number') {
    isValid = isValidNumber(value, element);
  } else if (element.customRegex && element.customRegex.length > 0) {
    isValid = isValidRegex(value, element);
  } else {
    isValid = isValidStringLength(value, element);
  }

  if (
    isValid.error &&
    element.customErrorMessage &&
    element.customErrorMessage.length > 0
  ) {
    return {
      error: element.customErrorMessage
    };
  }

  return isValid;
};

const isValidRegex = (value, element) => {
  if (element.customRegex && element.customRegex.length > 0) {
    try {
      const result = value?.matchAll
        ? [...value.matchAll(element.customRegex)].length > 0
        : [];
      if (!result) {
        return {
          error: 'Error: Regex not matched'
        };
      }

      return true;
    } catch {
      return {
        error: 'Error: Regex not matched'
      };
    }
  }
  return true;
};

const isValidStringLength = (value, element) => {
  if (element.minLength && (!value || value.length < element.minLength)) {
    return {
      error: `Error: ${element.minLength} characters minimum`
    };
  }
  if (element.maxLength && (!value || value.length > element.maxLength)) {
    return {
      error: `Error: ${element.maxLength} characters max`
    };
  }

  return true;
};

const isValidNumber = (value, element) => {
  try {
    if (validator.isNumeric(value)) {
      const num = parseFloat(value);

      if (!element) {
        return true;
      }

      if (element) {
        if (element.minValue && num < parseFloat(element.minValue)) {
          return {
            error: `Error: minimum of ${element.minValue}`
          };
        } else if (element.maxValue && num > parseFloat(element.maxValue)) {
          return {
            error: `Error: maxmium of ${element.maxValue}`
          };
        }
        return true;
      }
    }
    return false;
  } catch {
    return false;
  }
};

const isValidDate = (date, element) => {
  if (!date || date.length !== 10) {
    if (element) {
      return {
        error: 'Error: Invalid date. Format as dd/MM/yyyy'
      };
    }

    return false;
  }
  const parts = date.split('/');
  if (parts.length !== 3) {
    return false;
  }

  try {
    /* eslint-disable no-unused-vars */
    const myDate = new Date(
      parseInt(parts[2]),
      parseInt(parts[1]),
      parseInt(parts[0])
    );
    return true;
  } catch {
    return {
      error: 'Error: Invalid date. Format as dd/MM/yyyy'
    };
  }
  //return validator.isDate(myDate);
};
export {
  isValidEmail,
  isValidPassword,
  isValidTextEntry,
  isValidFirstOrLastName,
  isValidTextInputEntry,
  isValidDate
};
