import React from 'react';
import ContentWidget from '../../../../components/ContentWidget';
import Rating from './Rating';

const RateYourDynamic = ({ dynamic }) => {
  return (
    <ContentWidget
      title="Rate your Communicator Dynamic"
      subtitle="Tell Us? How did we do?"
      height={400}
      margin={false}
      rounded={false}>
      <div className="flex flex-col justify-center items-center pt-4 mt-2 overflow-hidden items-center">
        <span className="text-sm mb-2">
          Please help us improve our algorithms further by rating how you feel
          each aspect of your communicator dynamic for your conversation with
          Bob about finances seems accurate to you.
        </span>
        {dynamic?.facets.map((facet, index) => (
          <Rating key={index} facet={facet} />
        ))}
      </div>
    </ContentWidget>
  );
};

export default RateYourDynamic;
