import React from 'react';
import useAssessment from '../../../../hooks/assessment';
import ContentWidget from '../../../ContentWidget';
import Button from '../../../Button';

const Name = () => {
  const { setName, nextStep, context } = useAssessment();

  return (
    <div className="mx-auto max-w-lg">
      <ContentWidget title="What is your first name?">
        <div className="mt-3 flex w-full justify-center">
          <input
            type="text"
            defaultValue={context?.name}
            onChange={e => setName(e.target.value)}
            name="name"
            id="name"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
            placeholder="i.e. John"
          />
        </div>
        <div className="flex w-full justify-center">
          <Button
            onClick={() => {
              setName('John');
              nextStep();
            }}
            text="CONTINUE"
          />
        </div>
      </ContentWidget>
    </div>
  );
};

export default Name;
