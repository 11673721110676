import React, { useEffect, useState } from 'react';
import useTeamMember from '../../../../hooks/teammember';
import { friendlyTimestamp } from '../../../../utils/Timestamp';
import TeamMemberDynamic from '../../TeamMemberDynamic';
import useTeams from '../../../../hooks/teams';
import useConversations from '../../../../hooks/conversations';

const TeamMemberDynamicsList = ({ teamMember }) => {
  const { teams } = useTeams();
  const { dynamics } = useTeamMember(teamMember);
  const { conversations } = useConversations();

  const [selectedParticipant, setSelectedParticipant] = useState();

  useEffect(() => {
    setSelectedParticipant(undefined);
  }, [teamMember]);

  const displayDynamic = dynamic => {
    const team = teams.find(t =>
      t.members.some(m => m.id === dynamic.teamMemberId)
    );
    const conversation = conversations.find(c =>
      c.participants.some(p => p.teamMemberId === dynamic.teamMemberId)
    );

    const participant = conversation.participants.find(
      p => p.teamMemberId === dynamic.teamMemberId
    );

    if (team) {
      setSelectedParticipant({
        team,
        participant,
        conversation,
        teamMember: team.members.find(m => m.id === dynamic.teamMemberId)
      });
    } else {
      setSelectedParticipant(undefined);
    }
  };

  if (selectedParticipant) {
    return <TeamMemberDynamic {...selectedParticipant} hideUserTitle />;
  }

  if (!dynamics || dynamics.length === 0) {
    return (
      <div className="min-w-full text-center mt-8 text-gray-700">
        No Communicator Dynamics tests have been completed yet by this team
        member.
      </div>
    );
  }

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {dynamics?.map((dynamic, index) => (
          <div
            key={index}
            onClick={e => {
              e.preventDefault();
              displayDynamic(dynamic);
            }}
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 text-center bg-cd-green hover:bg-cd-red hover:text-cd-navy cursor-pointer hover:border-cd-red hover:border-1">
            <dt className="truncate text-sm font-medium text-gray-200 flex flex-col mt-2">
              <span className="text-xs">Archetype</span>
            </dt>
            <dd className="mt-1 text-3xl font-semibold text-white font-merriweather">
              {dynamic.archetype}
            </dd>
            <dt className="truncate text-sm text-gray-200 flex flex-col mt-2">
              <span className="text-xs">for the conversation:</span>"
              {dynamic.subject}"
            </dt>
            <dd className="mt-1 text-sm text-gray-200">
              {friendlyTimestamp(dynamic.createdAt)}
            </dd>
            <dd className="mt-2 text-sm text-gray-200">
              Team: {dynamic.teamName}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default TeamMemberDynamicsList;
