import React from 'react';
import useAssessment from '../../../../hooks/assessment';
import ContentWidget from '../../../ContentWidget';
import Button from '../../../Button';

const Pronouns = () => {
  const { setPronouns, nextStep } = useAssessment();

  return (
    <div className="mx-auto max-w-lg">
      <ContentWidget title="What are your pronouns?">
        <div className="p-8 text-md text-center">Enter your pronouns</div>
        <div className="flex w-full justify-center">
          <Button
            onClick={() => {
              setPronouns('he/him');
              nextStep();
            }}
            text="CONTINUE"
          />
        </div>
      </ContentWidget>
    </div>
  );
};

export default Pronouns;
