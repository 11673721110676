import React from 'react';
import FacetGoal from './FacetGoal';
import goalsBg from '../../../../assets/celebration-bg.png';
import videoPlaceholderSmall from '../../../../assets/videoplaceholder-sm.png';

const TransformationPanel = ({ dynamic }) => {
  return (
    <div
      className="min-w-full flex flex-col bg-white rounded px-30 py-10 text-center items-center"
      style={{
        backgroundImage: `url(${goalsBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}>
      <div className="w-4/5 lg:w-2/5 font-merriweather text-xl font-bold mt-5">
        Transform: Set Learning Goals to Improve or Change aspects of this
        Communicator Dynamic
      </div>
      <div className="min-w-full px-4 lg:px-0 lg:w-4/6 font-merriweather text-md font-medium mt-4">
        Now that you have identified your Communicator Dynamic for the
        conversation with Bob about finances, and educated yourself on what each
        aspect of the dynamic means, you can choose to either improve on this
        dynamic or transform to a completely different dynamic. Watch the video
        below to discover more about transforming your Communicator Dynamic
      </div>
      <div className="flex flex-col p-4 overflow-hidden w-4/5 bg-gray-200 lg:px-8 mt-6 rounded-lg">
        <div className="mx-auto grid grid-cols-1 lg:gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 items-center min-w-full content-start">
          <div className="col-span-2">
            {dynamic?.facets.map((facet, index) => (
              <FacetGoal key={index} facet={facet} dynamic={dynamic} />
            ))}
          </div>
          <div className="flex flex-col align-top h-auto mt-4 lg:mt-0 items-center">
            <span className="font-bold">
              The following videos have been chosen for you to help you reach
              your goals in this conversation
            </span>
            <div className="flex flex-col">
              <div className="flex flex-col text-center items-center my-2 mx-auto">
                <img
                  src={videoPlaceholderSmall}
                  alt=""
                  width={180}
                  className="mr-2"
                />
                About this video
              </div>
              <div className="flex flex-col text-center items-center my-2 mx-auto">
                <img
                  src={videoPlaceholderSmall}
                  alt=""
                  className="mr-2"
                  width={180}
                />
                About this video
              </div>
              <div className="flex flex-col text-center items-center my-2 mx-auto">
                <img
                  src={videoPlaceholderSmall}
                  alt=""
                  className="mr-2"
                  width={180}
                />
                About this video
              </div>
              <div className="flex flex-col text-center items-center my-2 mx-auto">
                <img
                  src={videoPlaceholderSmall}
                  alt=""
                  className="mr-2"
                  width={180}
                />
                About this video
              </div>
              <div className="flex flex-col text-center items-center my-2 mx-auto">
                <img
                  src={videoPlaceholderSmall}
                  alt=""
                  className="mr-2"
                  width={180}
                />
                About this video
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransformationPanel;
