import React from 'react';

import useAssessment from '../../hooks/assessment';
import {
  ASSESSMENT_STEPS,
  Gender,
  Introduction,
  Pronouns,
  Question,
  Result,
  Subject,
  Name,
  Setting,
  With
} from './Steps';

const Assessment = () => {
  const { step } = useAssessment();

  const renderStep = step => {
    switch (step) {
      case ASSESSMENT_STEPS.GENDER:
        return <Gender />;
      case ASSESSMENT_STEPS.NAME:
        return <Name />;
      case ASSESSMENT_STEPS.PRONOUNS:
        return <Pronouns />;
      case ASSESSMENT_STEPS.QUESTION:
        return <Question />;
      case ASSESSMENT_STEPS.RESULT:
        return <Result />;
      case ASSESSMENT_STEPS.SETTING:
        return <Setting />;
      case ASSESSMENT_STEPS.SUBJECT:
        return <Subject />;
      case ASSESSMENT_STEPS.WITH:
        return <With />;
      default:
        return <Introduction />;
    }
  };

  return <>{renderStep(step)}</>;
};

export default Assessment;
