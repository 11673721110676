import React from 'react';
import useAssessment from '../../../../hooks/assessment';
import ContentWidget from '../../../ContentWidget';
import Button from '../../../Button';

const Question = () => {
  const { answerYes, answerNo, question } = useAssessment();

  return (
    <div className="mx-auto max-w-lg">
      <ContentWidget title="Next Question">
        <div className="p-8 text-md text-center">{question.question}</div>
        <div className="flex w-full justify-center">
          <Button onClick={answerYes} text="Yes" />
          <Button onClick={answerNo} text="No" />
        </div>
      </ContentWidget>
    </div>
  );
};

export default Question;
