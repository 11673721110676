import React from 'react';
import bgBook from '../../../assets/book-banner-bg.png';
import bgApp from '../../../assets/app-banner-bg.png';
import playstore from '../../../assets/playstore-available.png';
import applestore from '../../../assets/ios-available.png';
import purchaseOnAmazon from '../../../assets/purchase-on-amazon.png';

/*
const GetBookOrAppBanner = () => {
  return (
    <div class="grid grid-cols-2 gap-0 flex">
      <div className="border-2 border-blue-700 flex">01</div>
      <div className="border-2 border-green-700 flex">02</div>
    </div>
  );
};
*/

const GetBookOrAppBanner = () => {
  return (
    <div className="lg:m-4">
      <div className="hidden lg:grid grid-cols-2 gap-x-0 gap-y-0 w-full flex">
        <div
          className="flex flex-col"
          style={{
            backgroundImage: `url(${bgBook})`,
            width: 594,
            height: 133,
            paddingTop: 30,
            paddingLeft: 250
          }}>
          <span className="font-merriweather font-bold text-2xl text-white">
            Get The Book
          </span>
          <a href="https://www.amazon.co.uk" target="_blank" rel="noreferrer">
            <img
              src={purchaseOnAmazon}
              alt=""
              style={{ width: 281, height: 34, marginTop: 10 }}
            />
          </a>
        </div>
        <div
          className="flex flex-col"
          style={{
            backgroundImage: `url(${bgApp})`,
            width: 594,
            height: 133,
            paddingTop: 30,
            paddingLeft: 220
          }}>
          <span className="font-merriweather font-bold text-2xl text-white mb-1">
            Get The App
          </span>
          <div className="flex">
            <a href="https://play.google.com" target="_blank" rel="noreferrer">
              <img
                src={playstore}
                alt=""
                style={{ width: 156, height: 53, marginRight: 20 }}
              />
            </a>
            <a href="https://www.apple.com" target="_blank" rel="noreferrer">
              <img src={applestore} alt="" style={{ width: 156, height: 53 }} />
            </a>
          </div>
        </div>
      </div>
      <div className="block lg:hidden">
        <div
          className="flex flex-col"
          style={{
            backgroundImage: `url(${bgBook})`,
            backgroundPosition: 'center',
            height: 133,
            paddingTop: 30,
            paddingLeft: 100
          }}>
          <span className="font-merriweather font-bold text-2xl text-white text-left pl-2">
            Get The Book
          </span>
          <a href="https://www.amazon.co.uk" target="_blank" rel="noreferrer">
            <img
              src={purchaseOnAmazon}
              alt=""
              style={{ width: 281, height: 34, marginTop: 10 }}
            />
          </a>
        </div>
        <div
          className="flex flex-col  bg-cd-red p-8"
          style={{
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}>
          <span className="font-merriweather font-bold text-2xl text-white mb-2 text-center">
            Get The App
          </span>
          <div className="flex mt-1">
            <a href="https://play.google.com" target="_blank" rel="noreferrer">
              <img
                src={playstore}
                alt=""
                style={{ width: 156, height: 53, marginRight: 20 }}
              />
            </a>
            <a href="https://www.apple.com" target="_blank" rel="noreferrer">
              <img src={applestore} alt="" style={{ width: 156, height: 53 }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetBookOrAppBanner;
