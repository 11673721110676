import React, { useEffect } from 'react';
import videoExplainerPlaceholder from '../../../../assets//video-placeholder.png';
import useAssessment from '../../../../hooks/assessment';
import ContentWidget from '../../../ContentWidget';
import Button from '../../../Button';

const Introduction = () => {
  const { initialiseNewAssessment, nextStep } = useAssessment();

  useEffect(() => {
    initialiseNewAssessment();
  }, []);

  return (
    <ContentWidget title="Discover Your Communicator Dynamic">
      <div className="flex grid grid-cols-1 lg:grid-cols-2 p-8 text-md items-center">
        <img
          src={videoExplainerPlaceholder}
          className="mt-6 opacity-50 flex"
          alt=""
        />
        <p className="text-center mt-3 lg:mt-0 lg:text-left">
          It’s fun and fascinating to discover your Communicator Dynamic for a
          particular conversation. Now take the next step and decide how you
          want to develop your skills again and this is that.
        </p>
      </div>
      <div className="flex w-full justify-center">
        <Button onClick={nextStep} text="TAKE THE TEST" />
      </div>
    </ContentWidget>
  );
};

export default Introduction;
