import { CONVERSATION_ACTIONS } from '../../actions/conversations';

const initialState = {
  conversations: []
};

const ConversationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONVERSATION_ACTIONS.CONVERSATIONS_LOADED:
      return {
        ...state,
        conversations: action.payload
      };
    case 'INVALID_USER':
      return initialState;
    case 'USER_LOGOUT':
      return initialState;

    default:
      return state;
  }
};

export default ConversationsReducer;
