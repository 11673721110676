import React from 'react';
import ContentWidget from '../../../components/ContentWidget';
import findACoach from '../../../assets/findcoach.png';
import Button from '../../../components/Button';

const FindACoach = () => {
  return (
    <ContentWidget title="Find a Coach">
      <img src={findACoach} className="mt-3 max-auto self-center" alt="" />
      <p className="text-md pt-4">
        Discover how you can invite others to take the test and overlay their
        results with yours as well as sharing your results and collaborating
        with others.
      </p>
      <div className="flex w-full justify-center">
        <Button path="/coaches" text="FIND A COACH" />
      </div>
    </ContentWidget>
  );
};

export default FindACoach;
