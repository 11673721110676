import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import {
  ArrowLeftIcon
  /*AtSymbolIcon,
  XCircleIcon*/
} from '@heroicons/react/24/solid';
import defaultAvatar from '../../../assets/default-avatar.png';
import useTeamMember from '../../../hooks/teammember';
import DynamicBreakdown from '../../../components/DynamicBreakdown';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const TeamMemberDynamic = props => {
  const {
    teamMember,
    team,
    onClose,
    conversation,
    hideUserTitle = false
  } = props;

  const { dynamics } = useTeamMember(teamMember);
  const [dynamic, setDynamic] = useState();

  const {
    firstName,
    lastName,
    status
    /*email,
    jobTitle,
    department,
    notes,
    id,
    teamId*/
  } = teamMember;

  useEffect(() => {
    if (conversation && dynamics.length > 0) {
      setDynamic(dynamics.find(d => d.conversationId === conversation.id));
    }
  }, [dynamics]);

  const getProfilePhoto = photoUrl => {
    if (photoUrl && Array.isArray(photoUrl) && photoUrl.length > 0) {
      return photoUrl[0].thumb;
    } else {
      return defaultAvatar;
    }
  };

  return (
    <div className="bg-white px-4 py-5 sm:px-6 w-full">
      {!hideUserTitle ? (
        <div className="flex space-x-3 border-b pb-4">
          <div className="flex-shrink-0 flex items-center">
            <img
              className="h-10 w-10 rounded-full"
              src={getProfilePhoto(teamMember.profilePhotoUrl)}
              alt=""
            />
          </div>
          <div className="min-w-0 flex-1 items-center ">
            <div className="font-merriweather text-2xl font-bold text-cd-navy items-center">
              {firstName} {lastName}
            </div>
            <div className="flex text-gray-500">
              <span>
                {`Team: ${team.name}`}, Status: {status}
              </span>
            </div>
          </div>

          <div className="flex flex-shrink-0 self-center ">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-cd-navy hover:text-gray-600">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {/*<Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          onDelete();
                        }}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-cd-navy',
                          'flex px-4 py-2 text-sm'
                        )}>
                        <XCircleIcon
                          className="mr-3 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span>Remove from Team</span>
                      </a>
                    )}
                  </Menu.Item>
                  {status !== 'accepted' ? (
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'flex px-4 py-2 text-sm'
                          )}>
                          <AtSymbolIcon
                            className="mr-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span>Re-Send Invite</span>
                        </a>
                      )}
                    </Menu.Item>
                          ) : null}*/}
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          onClick={onClose}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-cd-navy',
                            'flex px-4 py-2 text-sm'
                          )}>
                          <ArrowLeftIcon
                            className="mr-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span>Close this Panel</span>
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      ) : null}
      {dynamic ? <DynamicBreakdown hideTitle dynamic={dynamic} /> : null}
    </div>
  );
};

export default TeamMemberDynamic;
