import React from 'react';
import useAssessment from '../../../../hooks/assessment';
import ContentWidget from '../../../ContentWidget';
import Button from '../../../Button';

const Setting = () => {
  const { setSetting, nextStep } = useAssessment();

  return (
    <div className="mx-auto max-w-lg">
      <ContentWidget title="Conversation Setting?">
        <div className="p-8 text-md text-center">
          Where is this conversation taking place?
        </div>
        <div className="flex w-full justify-center">
          <Button
            onClick={() => {
              setSetting('Face to Face');
              nextStep();
            }}
            text="CONTINUE"
          />
        </div>
      </ContentWidget>
    </div>
  );
};

export default Setting;
