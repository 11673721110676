import React from 'react';
import videoPlaceholderSmall from '../../../assets/videoplaceholder-sm.png';

const DiscoverBanner = () => {
  return (
    <div className="bg-white rounded-lg p-12 flex mb-4">
      <div className="mr-4">
        <img src={videoPlaceholderSmall} alt="" className="mr-2" width={183} />
      </div>
      <div className="flex items-center w-3/5 font-merriweather font-bold text-2xl">
        Discover Your Team’s Communicator Dynamics for a Conversation that
        matters to you all.
      </div>
    </div>
  );
};

export default DiscoverBanner;
