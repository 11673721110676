import React, { useState } from 'react';

import CompanyLogo from '../../assets/company-logo.png';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import Version from '../../version';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';

const ForgotPassword = ({ close }) => {
  const [recoverError, setRecoverError] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const recover = async e => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => setShowSuccess(true))
      .catch(error => setRecoverError(error));
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-20 w-auto mb-6" src={CompanyLogo} alt="" />
        <h2 className="mt-8 text-center text-3xl font-extrabold text-gray-900 my-8 font-merriweather">
          Reset Your Password
        </h2>
        {!showSuccess ? (
          <p className="mt-2 text-center text-md text-gray-600">
            Enter your email below and we will email you a password reset link
            if your email is registered with us.
          </p>
        ) : null}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md items-center">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {recoverError && (
            <div className="text-red-700 mb-2">{recoverError.message}</div>
          )}
          {showSuccess && (
            <div className="flex flex-col items-center min-w-full">
              <div className="text-green text-sm text-center mb-4">
                <p>
                  If the email address is registered with us, we have just sent
                  you an email with instructions on how to reset your password.
                </p>
                <br />
                <p>Please check your email and your Spam folder.</p>
              </div>
              <div className="mt-2">
                <Button path="/login" text="Back to Login" />
              </div>
            </div>
          )}
          {!showSuccess && (
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={e => setEmail(e.target.value)}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex flex-col items-center justify-between text-center">
                <Button onClick={recover} text="Send Password Reset Email" />

                <Link
                  to="/login"
                  className="text-sm text-cd-navy hover:text-cd-red">
                  Back to Login?
                </Link>
              </div>
            </form>
          )}
        </div>
        <Version />
      </div>
    </div>
  );
};
export default ForgotPassword;
