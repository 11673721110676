import React from 'react';
import useAssessment from '../../../../hooks/assessment';
import ContentWidget from '../../../ContentWidget';
import Button from '../../../Button';

const Subject = () => {
  const { setSubject, nextStep, context } = useAssessment();

  return (
    <div className="mx-auto max-w-lg">
      <ContentWidget title="The Conversation?">
        <div className="mt-3 flex w-full justify-center">
          <input
            type="text"
            defaultValue={context?.subject}
            onChange={e => setSubject(e.target.value)}
            name="subject"
            id="subject"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
            placeholder="i.e. Finances"
          />
        </div>
        <div className="flex w-full justify-center">
          <Button
            onClick={() => {
              setSubject('Finances');
              nextStep();
            }}
            text="CONTINUE"
          />
        </div>
      </ContentWidget>
    </div>
  );
};

export default Subject;
