import React from 'react';
import ContentWidget from '../../../../components/ContentWidget';
import DynamicVideo from '../DynamicVideo';

const UnderstandYourDynamic = ({ dynamic }) => {
  const videos = [
    {
      title: 'Is the Giver a push over?',
      subtitle: 'Learn more about roles in communication',
      videoUrl: undefined
    },
    {
      title: 'Maximising Your Operator',
      subtitle: 'Learn more about roles in communication',
      videoUrl: undefined
    },
    {
      title: 'Understanding The Benefitor',
      subtitle: 'Learn more about roles in communication',
      videoUrl: undefined
    }
  ];

  return (
    <ContentWidget
      title="Educate: Understand your Communicator Dynamic"
      subtitle={`Handpicked videos for you based on your Communicator Dynamic (The ${dynamic.archetype}) for your Conversation with ${dynamic.with} about ${dynamic.subject}. 
Watch the videos below to develop your communication skills.`}>
      <div className="mx-auto grid min-w-full grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:grid-cols-3 items-top text-center pt-6">
        {videos.map((video, index) => (
          <DynamicVideo
            key={index}
            title={video.title}
            subtitle={video.subtitle}
            videoUrl={video.videoUrl}
          />
        ))}
      </div>
    </ContentWidget>
  );
};

export default UnderstandYourDynamic;
