import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAssessment from '../../../../hooks/assessment';

const Result = () => {
  const navigate = useNavigate();
  const { initialiseNewAssessment } = useAssessment();
  useEffect(() => {
    initialiseNewAssessment();
    navigate('/dynamics');
  }, []);
  return <>Please wait...</>;
};

export default Result;
