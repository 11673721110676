import React from 'react';
import ContentWidget from '../../../components/ContentWidget';
import Button from '../../../components/Button';
import PadLockIcon from '../../../assets/icons/padlock.svg';
import fullTestPlaceholder from '../../../assets/icons/fulltest-placeholder.png';

const UnlockEverything = () => {
  return (
    <ContentWidget title="Unlock Everything" image={PadLockIcon}>
      <div className="flex flex-col justify-center items-center mx-auto">
        <img
          src={fullTestPlaceholder}
          className="my-6 opacity-50 mx-auto content-center"
          alt=""
        />
        <Button text="UNLOCK EVERYTHING" />
      </div>
    </ContentWidget>
  );
};

export default UnlockEverything;
