import { initializeApp } from 'firebase/app';
import { getFirestore, serverTimestamp } from '@firebase/firestore';
import { getAuth } from '@firebase/auth';
import firebaseConfig from './config.json';

const APP_TYPES = {
  ACTIVECOURSE: 'ACTIVECOURSE',
  BEAUTTY: 'BEAUTY',
  BOOKINGS: 'BOOKINGS',
  RECRUITMENT: 'RECRUITMENT',
  EMPTY: 'EMPTY',
  QUOTING: 'QUOTING',
  ALL: 'ALL',
  CUSTOM: 'CUSTOM'
};

const APP_TYPE = APP_TYPES.EMPTY;

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const auth = getAuth(app);

export { app, db, auth, serverTimestamp, APP_TYPE, APP_TYPES, firebaseConfig };
