import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { auth } from '../../firebase';
import CompanyLogo from '../../assets/company-logo.png';
import Version from '../../version';
import Button from '../../components/Button';
import { signOut, sendEmailVerification } from 'firebase/auth';
import { Link } from 'react-router-dom';

const VerificationEmailSent = ({ close }) => {
  const [resentStatus, setResentStatus] = useState();

  const dispatch = useDispatch();

  const resendVerificationEmail = () => {
    let currentUser;

    try {
      currentUser = auth.getUser();
    } catch {
      //
    }

    if (currentUser) {
      sendEmailVerification(auth, currentUser.email)
        .then(() =>
          setResentStatus({ message: 'The verification email re-sent to you.' })
        )
        .error(() => {
          signOut();
          setResentStatus({ error: 'An error occured re-sending the email.' });
        });
    } else {
      dispatch({ type: 'USER_LOGOUT', payload: {} });
      setResentStatus({
        error: 'Please login again so we can re-send your verification email.',
        showLoginAsButton: true
      });
    }
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 px-4">
      <div className="sm:mx-auto sm:w-full sm:max-w-md items-center text-center">
        <img className="mx-auto h-20 w-auto mb-6" src={CompanyLogo} alt="" />
        <h2 className="mt-8 text-center text-3xl font-extrabold text-gray-900 my-8 font-merriweather">
          Thanks for registering!
        </h2>
        {!resentStatus?.error ? (
          <>
            <p className="mt-2 text-center text-md text-gray-600">
              We have sent you a link by email. Please click the link to verify
              your email address. Check your junk/spam folder too.
            </p>
            <p className="mt-2 text-center text-md text-gray-600">
              If you did'nt receive it. Click the button below to re-send it.
            </p>

            <Button
              onClick={resendVerificationEmail}
              text="Re-Send Verification Email"
            />
          </>
        ) : null}
        {resentStatus ? (
          <>
            {resentStatus.error ? (
              <div className="text-cd-red">{resentStatus.error}</div>
            ) : (
              <div className="text-cd-green">{resentStatus.message}</div>
            )}
            {resentStatus?.error ? (
              resentStatus.showLoginAsButton ? (
                <Button path="/login" text="Login" />
              ) : (
                <Link to="/login">Login</Link>
              )
            ) : null}
          </>
        ) : null}
      </div>
      <Version />
    </div>
  );
};
export default VerificationEmailSent;
