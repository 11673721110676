import { Timestamp } from 'firebase/firestore';
import moment from 'moment/moment';

export const friendlyTimestamp = (data, includeTime) => {
  if (!data?.seconds || !data?.nanoseconds) {
    return '';
  }
  const ts = new Timestamp(data.seconds, data.nanoseconds);
  return moment(ts.toDate()).format(
    `${'Do MMM YYYY'} ${includeTime ? ' @ h:mm a' : ''}`
  );
};
