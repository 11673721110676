import React from 'react';
import ContentWidget from '../../../components/ContentWidget';
import Button from '../../../components/Button';
import { HeroIcon } from '../../../icons/HeroIcon';
import clsx from 'clsx';

const NextWorkhop = () => {
  const workshop = {
    title: 'Beginner Workshop',
    date: '03 Jun 2023',
    time: '3pm',
    location: 'Online Zoom Event',
    cost: 'Included With Essential Plan @ $3pm'
  };

  return (
    <ContentWidget
      title="Join The Next Workshop"
      backgroundColor="cd-navy"
      textColor="white"
      margin={false}
      rounded={false}
      alignTitle="center"
      height={340}
      hideTitleBorder>
      <div className="flex flex-col justify-center items-center p-4">
        <div className="text-white font-sans p-6 text-center border-dashed border border-white">
          <span className="text-xl">{workshop.title}</span>
          <div className="flex justify-evenly w-full">
            <div className="flex items-center my-4">
              <HeroIcon
                icon="CalendarIcon"
                className={clsx(`text-white mr-1`)}
                size={4}
                outline
              />
              <span>{workshop.date}</span>
            </div>
            <div className="flex items-center">
              <HeroIcon
                icon="ClockIcon"
                className={clsx(`text-white mr-1`)}
                size={4}
                outline
              />
              <span>{workshop.time}</span>
            </div>
          </div>
          <span>{workshop.location}</span>
          <span>{workshop.cost}</span>
        </div>
        <Button path="/workshops" green text="REGISTER NOW" />
      </div>
    </ContentWidget>
  );
};

export default NextWorkhop;
