import React, { useState } from 'react';
import clsx from 'clsx';
import Button from '../../../components/Button';
import teamIcon from '../../../assets/icons/team.svg';
import defaultAvatar from '../../../assets/default-avatar.png';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import AddTeamModal from '../AddTeamModal';
import AddTeamMemberModal from '../AddTeamMemberModal';
import useTeams from '../../../hooks/teams';

const TeamsNavigation = ({
  teams,
  onTeamSelected,
  onTeamMemberSelected,
  selectedTeamMember
}) => {
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [showAddTeamMemberModal, setShowAddTeamMemberModal] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState();

  const { createTeam, addTeamMember } = useTeams();

  const getProfilePhoto = photoUrl => {
    if (photoUrl && Array.isArray(photoUrl) && photoUrl.length > 0) {
      return photoUrl[0].thumb;
    } else {
      return defaultAvatar;
    }
  };

  const displayTeamMember = (teamMember, team) => {
    onTeamMemberSelected({
      teamMember,
      team
    });
  };

  return (
    <div className="min-w-full items-center text-center -mt-5">
      <AddTeamModal
        open={showAddTeamModal}
        onTeamNameEntered={teamName => {
          setShowAddTeamModal(false);
          createTeam(teamName);
        }}
        setOpen={setShowAddTeamModal}
      />
      <AddTeamMemberModal
        open={showAddTeamMemberModal}
        onMemberDetailsEntered={data => {
          setShowAddTeamMemberModal(false);
          addTeamMember({ ...data, teamId: selectedTeamId });
        }}
        setOpen={setShowAddTeamMemberModal}
      />
      <div className="text-white font-merriweather text-sm px-[10%]">
        Communicator Dynamics® for Teams
      </div>
      <Button
        fontNormal
        text="CREATE NEW TEAM"
        green
        icon={teamIcon}
        onClick={() => setShowAddTeamModal(true)}
      />
      <div className="text-white font-merriweather text-2xl text-left px-[10%] my-8 items-left">
        My Teams
      </div>
      <div className="flex flex-col ">
        {teams?.length > 0 ? (
          <>
            {teams.map((team, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col min-w-full mb-5 items-left pl-[10%] ">
                  <div className="flex items-center mb-2">
                    <div
                      className="flex text-md text-white font-sans cursor-pointer hover:underline"
                      onClick={() => onTeamSelected(team)}>
                      {team.name.toUpperCase()}
                    </div>
                    <div
                      className="text-white ml-2 cursor-pointer"
                      onClick={() => {
                        setSelectedTeamId(team.id);
                        setShowAddTeamMemberModal(true);
                      }}>
                      <PlusCircleIcon className="h-8" aria-hidden="true" />
                    </div>
                  </div>
                  {team.members.map((member, index) => (
                    <div
                      key={`team_${index}`}
                      onClick={() => displayTeamMember(member, team)}
                      className={clsx(
                        member.id === selectedTeamMember?.teamMember?.id
                          ? 'border-2 border-white'
                          : '',
                        'flex my-1 rounded-xl p-2 hover:border-gray-700 cursor-pointer hover:border-2 hover:border-white cursor-pointer'
                      )}
                      style={{ backgroundColor: '#404C5B' }}>
                      <img
                        className="h-10 w-10 rounded-full mt-1 mr-4"
                        src={getProfilePhoto(member.profilePhotoUrl)}
                        alt=""
                      />
                      <div className="flex items-center">
                        <div className="flex flex-col">
                          <span className="text-white text-md text-left">
                            {member.firstName} {member.lastName}
                          </span>
                          <span className="text-gray-400 text-xs text-left">
                            {member.jobTitle}
                            {member.status !== 'active'
                              ? ` (${member.status})`
                              : ''}
                          </span>
                        </div>
                        <div className="hidden self-end items-center flex">
                          <PlusCircleIcon
                            className="h-6 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </>
        ) : (
          <span className="text-sm text-gray-600">You have no teams yet.</span>
        )}
      </div>
    </div>
  );
};

export default TeamsNavigation;
