import React from 'react';

import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import store from './redux/config';
import { Provider } from 'react-redux';
import LoadingSpinner from './utils/LoadingSpinner';
import Scaffolding from './scaffolding';

const App = () => {
  const [user, loading] = useAuthState(auth);

  return (
    <Provider store={store}>
      {loading && !user ? (
        <LoadingSpinner footertext="Hang tight..." />
      ) : (
        <Scaffolding />
      )}
    </Provider>
  );
};

export default App;
