import React, { useEffect, useState } from 'react';
import useDynamics from '../../hooks/dynamics';

import clsx from 'clsx';

import Layout from '../../scaffolding/Layout';
import NoRecentDynamic from './NoRecentDynamic';
import SelectedDynamic from './SelectedDynamic';
import DynamicsList from './DynamicsList';

const MyDynamics = () => {
  const { latestDynamic: dynamic, dynamics } = useDynamics();

  const [selectedDynamic, setSelectedDynamic] = useState();

  useEffect(() => {
    if (dynamic && !selectedDynamic) {
      setSelectedDynamic(dynamic);
    }
  }, [dynamic]);

  return (
    <Layout>
      <div className="grid grid-cols-1 gap-x-4 lg:grid-cols-8 rounded-lg min-w-full">
        <div
          className={clsx(
            'flex min-w-full items center min-h-full',
            dynamics && dynamics.length > 1 ? 'lg:col-span-6' : 'lg:col-span-8'
          )}>
          {selectedDynamic ? (
            <SelectedDynamic dynamic={selectedDynamic} />
          ) : (
            <NoRecentDynamic />
          )}
        </div>
        {dynamics && dynamics.length > 1 ? (
          <div className="flex lg:col-span-2 min-w-full bg-white ">
            <DynamicsList
              setSelectedDynamic={setSelectedDynamic}
              selectedDynamic={selectedDynamic}
              dynamics={dynamics}
            />
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default MyDynamics;
