import React, { useEffect, useState } from 'react';
import ConversationList from './ConversationList';
import Conversation from './Conversation';
import useConversations from '../../../hooks/conversations';
import TeamMember from '../TeamMember';

const Conversations = ({
  selectedTeamMember,
  onCloseTeamMember,
  onDeleteTeamMember
}) => {
  const { conversations } = useConversations();
  const [selectedConversation, setSelectedConversation] = useState(
    conversations[0]
  );

  useEffect(() => {
    if (selectedConversation) {
      const updatedConversation = conversations.find(
        c => c.id === selectedConversation.id
      );
      setSelectedConversation(updatedConversation);
    }
  }, [conversations]);

  const onConversationDeleted = () => {
    onCloseTeamMember();
    setSelectedConversation(undefined);
  };

  const onConversationSelected = conversation => {
    onCloseTeamMember();
    setSelectedConversation(conversation);
  };

  return (
    <div className="grid grid-cols-1 gap-x-4 lg:grid-cols-8 bg-white rounded-lg min-w-full">
      <div className="flex col-span-6 min-w-full items center min-h-full">
        {selectedTeamMember ? (
          <>
            <TeamMember
              teamMember={selectedTeamMember.teamMember}
              team={selectedTeamMember.team}
              onClose={onCloseTeamMember}
              onDelete={onDeleteTeamMember}
            />
          </>
        ) : null}
        <>
          {!selectedTeamMember ? (
            <>
              {selectedConversation ? (
                <Conversation
                  conversation={selectedConversation}
                  onDeleted={onConversationDeleted}
                />
              ) : (
                <div className="items-center h-96 flex flex-col mt-12 text-center mx-auto">
                  <p>
                    Add your first conversation or select an existing
                    conversation.
                  </p>
                  <p>
                    Add Team Members so you can invite them to participate in
                    conversations.
                  </p>
                  <p>
                    Get insights into the Communicator Dynamics® of your Teams.
                  </p>
                </div>
              )}{' '}
            </>
          ) : null}
        </>
      </div>
      <div className="flex col-span-2 min-w-full">
        <ConversationList
          setSelectedConversation={onConversationSelected}
          selectedConversation={selectedConversation}
          conversations={conversations}
        />
      </div>
    </div>
  );
};

export default Conversations;
