import { ASSESSMENT_ACTIONS } from '../../actions/assessment';

import {
  ASSESSMENT_STEPS,
  ASSESSMENT_STEP_ORDER
} from '../../../components/Assessment/Steps';

const initialState = {
  step: ASSESSMENT_STEPS.INTRODUCTION,
  context: {
    gender: 'unknown',
    name: '',
    genderPronouns: '',
    setting: '',
    subject: '',
    with: ''
  },
  question: undefined, // the current question being asked
  result: {},
  document: undefined,
  facets: undefined
};

/* a data store for the current assessment (test) being executed */
const AssessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSESSMENT_ACTIONS.NEXT_QUESTION: {
      return {
        ...state,
        question: action.payload
      };
    }
    case ASSESSMENT_ACTIONS.NEXT_STEP: {
      const stepIndex = ASSESSMENT_STEP_ORDER.findIndex(s => s === state.step);
      if (stepIndex === ASSESSMENT_STEP_ORDER.length - 1) {
        return { ...state };
      }
      return {
        ...state,
        step: ASSESSMENT_STEP_ORDER[stepIndex + 1]
      };
    }
    case ASSESSMENT_ACTIONS.PREVIOUS_STEP: {
      const stepIndex = ASSESSMENT_STEP_ORDER.findIndex(s => s === state.step);
      if (stepIndex === 0) {
        return { ...state };
      }
      return {
        ...state,
        step: ASSESSMENT_STEP_ORDER[stepIndex - 1]
      };
    }
    case ASSESSMENT_ACTIONS.START:
      return {
        ...state,
        ...action.payload
      };
    case ASSESSMENT_ACTIONS.INITIALISE:
      const newState = { ...initialState };
      newState.context.name = action.payload.name;
      return newState;
    case ASSESSMENT_ACTIONS.SET_CONTEXT_DATA: {
      const newState = { ...state };
      newState.context[action.payload.key] = action.payload.value;
      return newState;
    }

    default:
      return state;
  }
};

export default AssessmentReducer;
