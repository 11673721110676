import React, { Fragment, useState, useEffect } from 'react';
import { LockClosedIcon, BookOpenIcon } from '@heroicons/react/24/solid';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UserNav from '../Navigation/MenuItems/UserNav';
import defaultAvatar from '../../assets/default-avatar.png';
import MobileNavigation from '../Navigation/MobileNavigation';
// import CompanyIcon from '../../assets/company-icon.png';
import CompanyLogo from '../../assets/company-logo.png';

const Header = () => {
  const [profilePhoto, setProfilePhoto] = useState();

  const user = useSelector(state => state.User);

  useEffect(() => {
    if (
      user.profilePhoto &&
      Array.isArray(user.profilePhoto) &&
      user.profilePhoto.length > 0
    ) {
      setProfilePhoto(user.profilePhoto[0].thumb);
    } else {
      setProfilePhoto(defaultAvatar);
    }
  }, [user]);

  let [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    function onScroll() {
      setIsScrolled(window.scrollY > 0);
    }
    onScroll();
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <header
      className={clsx(
        'sticky top-0 z-50 flex flex-wrap items-center justify-between bg-white px-4 py-5 shadow-md shadow-slate-900/5 transition duration-500 dark:shadow-none sm:px-6 lg:px-8',
        isScrolled
          ? 'dark:bg-slate-900/95 dark:backdrop-blur dark:[@supports(backdrop-filter:blur(0))]:bg-slate-900/75'
          : 'dark:bg-transparent'
      )}>
      <div className="mr-6 flex lg:hidden">
        <MobileNavigation />
      </div>
      <div className="relative flex flex-grow basis-0 items-center">
        <Link to="/" aria-label="Home page">
          <img className="w-16 lg:hidden" src={CompanyLogo} alt="" />
          <img
            className="hidden h-9 w-auto lg:block"
            src={CompanyLogo}
            alt=""
          />
        </Link>
      </div>
      <div className="-my-5 mr-6 sm:mr-8 md:mr-0 hidden lg:block">
        <div className="flex">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-cd-navy text-sm font-medium rounded-md shadow-sm hover:text-white text-cd-navy bg-white hover:bg-cd-navy">
            <BookOpenIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Get The Book
          </button>
          <button
            type="button"
            className="ml-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm hover:text-white text-cd-navy bg-cd-yellow hover:bg-cd-navy">
            <LockClosedIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Unlock Everything
          </button>
        </div>
      </div>
      <div className="relative flex basis-0 justify-end gap-6 sm:gap-8 md:flex-grow">
        {/* Profile dropdown */}
        <Menu as="div" className="relative flex-shrink-0">
          <div>
            <Menu.Button className="bg-white rounded-full flex text-sm cursor-pointer">
              <span className="sr-only">Open user menu</span>
              {profilePhoto && (
                <img
                  className="h-8 w-8 rounded-full mt-1"
                  src={profilePhoto}
                  alt=""
                />
              )}
              <div className="inline-flex flex-col text-left ml-3">
                <div className="text-xs text-cd-red">{user.role}</div>
                {user.firstName} {user.lastName}
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <div className="bg-cd-navy flex px-3 py-8 mb-2">
                    {profilePhoto && (
                      <img
                        className="h-10 w-10 rounded-full mt-1 border-2 border-white"
                        src={profilePhoto}
                        alt=""
                      />
                    )}
                    <div className="inline-flex text-white flex-col text-left ml-3">
                      <span className="text-bold">
                        {user.firstName} {user.lastName}
                      </span>
                      <div className="text-xs text-white">
                        {user.email}{' '}
                        {user.session.emailVerified
                          ? '(verified)'
                          : '(pending verification)'}
                      </div>
                    </div>
                  </div>
                )}
              </Menu.Item>
              {UserNav.map(item => (
                <Menu.Item key={item.id}>
                  {({ active }) => (
                    <a
                      href={item.path}
                      onClick={item.onClick}
                      className={clsx(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}>
                      {item.title}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
  );
};

export default Header;
