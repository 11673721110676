import React from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { HeroIcon } from '../../../icons/HeroIcon';
import Version from '../../../version';
import items from './PrimaryNav';

const MenuItems = () => {
  //const { onSelected } = props;

  const isActiveMenuItem = item => {
    return item.path === window.location.pathname;
  };

  return (
    <nav className="flex-1 space-y-1 px-2 pb-4">
      {items &&
        items.map((i, index) => {
          const item = Object.assign({}, i);

          return (
            <div key={index} className="cursor-pointer">
              {item.heading && (
                <div className="py-1 ml-2 mb-2 text-xs text-gray-500">
                  {item.heading}
                </div>
              )}
              <Link
                data-testid={`lnk${item.name}`}
                to={item.path}
                /*onClick={e => {
                  onSelected(item, e);
                }}*/
                className={clsx(
                  isActiveMenuItem(item)
                    ? 'bg-cd-red text-white'
                    : 'text-cd-navy hover:bg-cd-red hover:text-white',
                  'group w-full p-3 rounded-md flex flex-col text-sm font-medium cursor-pointer font-open-sans mb-1'
                )}>
                <div className="flex ">
                  {item.icon && (
                    <div style={{ width: 20 }}>
                      <img
                        className="h-4 w-auto"
                        src={
                          isActiveMenuItem(item) ? item.iconActive : item.icon
                        }
                        alt=""
                      />
                    </div>
                  )}
                  {item.iconName && (
                    <HeroIcon
                      icon={item.iconName}
                      className={clsx(
                        isActiveMenuItem(item)
                          ? 'text-white'
                          : 'stroke-cd-navy group-hover:stroke-white fill-current'
                      )}
                      size={4}
                      outline
                    />
                  )}
                  <span className="ml-2 text-center uppercase">
                    {item.name}
                  </span>
                </div>
              </Link>
              {item.showDividerAfter && (
                <div className="border-b-2 border-gray-100 mb-6">&nbsp;</div>
              )}
            </div>
          );
        })}
      <Version mode="sidebar" />
    </nav>
  );
};

export default MenuItems;
