import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator
} from 'firebase/functions';

const useAutomations = () => {
  const functions = getFunctions();

  if (process.env.NODE_ENV === 'development') {
    //console.log('useCloud running against emulators on 5001...');
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }

  const startNewUserAutomations = async userId => {
    const newUserAutomationsFunc = httpsCallable(functions, 'cdNewUser');
    await newUserAutomationsFunc({
      userId
    });
  };

  return {
    startNewUserAutomations
  };
};

export default useAutomations;
