import React from 'react';
import ContentWidget from '../../../../components/ContentWidget';
import whatsNext1 from '../../../../assets/whatsnext-1.png';
import whatsNext2 from '../../../../assets/whatsnext-2.png';
import whatsNext3 from '../../../../assets/whatsnext-3.png';

const WhatsNext = () => {
  return (
    <ContentWidget title="What's Next?">
      <div className="mx-auto grid min-w-full grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-8 lg:mx-0 items-top text-center pt-8">
        <div className="flex flex-col">
          <img src={whatsNext1} alt="" className="mx-auto" width={250} />
          <span className="text-center font-merriweather mt-4 text-md">
            Identify your Communicator Dynamic for a particular conversation and
            its sub-facets.
          </span>
        </div>
        <div className="flex flex-col">
          <img src={whatsNext2} alt="" className="mx-auto" width={250} />
          <span className="text-center font-merriweather mt-4 text-md">
            Educate yourself on each aspect of your Communicator Dynamic with
            our library of videos and courses.
          </span>
        </div>
        <div className="flex flex-col">
          <img src={whatsNext3} alt="" className="mx-auto" width={250} />
          <span className="text-center font-merriweather mt-4 text-md">
            Decide if you want to improve aspects of your Communicator Dynamic
            for this particular conversation or transition to a completely new
            dynamic by setting communications goals.
          </span>
        </div>
      </div>
    </ContentWidget>
  );
};

export default WhatsNext;
