/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import useConversations from '../../../hooks/conversations';

const AddTeamMemberModal = props => {
  const { open, setOpen, onMemberDetailsEntered } = props;

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [department, setDepartment] = useState();
  const [notes, setNotes] = useState();
  const [conversationId, setConversationId] = useState();
  const { conversations } = useConversations();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left min-w-[85%]">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-gray-900 font-sans font-bold">
                      Invite a new Team Member
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-700">
                        Complete the team member's details below and click
                        Invite. They will then get emailed a link to join your
                        team so that you can invite them to take the test for
                        conversations that you invite them too.
                      </p>
                    </div>
                    <div className="mt-3">
                      <input
                        type="text"
                        onChange={e => setFirstName(e.target.value)}
                        name="firstName"
                        id="firstName"
                        className="min-w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="i.e. John"
                      />
                    </div>
                    <div className="mt-3">
                      <input
                        type="text"
                        onChange={e => setLastName(e.target.value)}
                        name="lastName"
                        id="lastName"
                        className="min-w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="i.e. Byrne"
                      />
                    </div>
                    <div className="mt-3">
                      <input
                        type="text"
                        onChange={e => setEmail(e.target.value)}
                        name="email"
                        id="email"
                        className="min-w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="i.e. john.byrne@gmail.com"
                      />
                    </div>
                    <div className="mt-3">
                      <input
                        type="text"
                        onChange={e => setJobTitle(e.target.value)}
                        name="jobTitle"
                        id="jobTitle"
                        className="min-w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Job Title i.e. Sales Manager"
                      />
                    </div>
                    <div className="hidden mt-3">
                      <input
                        type="text"
                        onChange={e => setDepartment(e.target.value)}
                        name="department"
                        id="department"
                        className="min-w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Department i.e. Outbound Sales"
                      />
                    </div>
                    {conversations?.length > 0 ? (
                      <div>
                        <select
                          id="location"
                          name="location"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          defaultValue=""
                          onChange={e => setConversationId(e.target.value)}>
                          <option>(Optional) Select a Conversation</option>
                          {conversations.map(conversation => (
                            <option
                              key={conversation.id}
                              value={conversation.id}>
                              {conversations.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}
                    <div className="mt-3 hidden">
                      <input
                        type="text"
                        onChange={e => setNotes(e.target.value)}
                        name="notes"
                        id="notes"
                        className="min-w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Any notes about this team member"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 mr-3 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={clsx(
                    firstName && lastName && email && jobTitle
                      ? 'bg-green-600'
                      : 'bg-gray-500 cursor-default hover:bg-gray-500',
                    'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white hover:bg-green-700 sm:ml-3 sm:w-auto sm:text-sm'
                  )}
                  onClick={() => {
                    if (firstName && lastName && email && jobTitle) {
                      onMemberDetailsEntered({
                        firstName,
                        lastName,
                        email,
                        jobTitle,
                        department,
                        notes,
                        conversationId
                      });
                    }
                  }}>
                  Invite
                </button>

                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddTeamMemberModal;
