import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator
} from 'firebase/functions';

import useUser from '../user';

/*
    Hook which provides ability to raise an invitation to join a team or a particular converastion
*/
const useInvitations = () => {
  const functions = getFunctions();

  if (process.env.NODE_ENV === 'development') {
    //console.log('useCloud running against emulators on 5001...');
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }

  const { userInvitations } = useUser();

  const createInvitation = async ({
    teamId,
    teamMemberId,
    teamName,
    conversationId,
    conversationTitle,
    recipientName,
    recipientEmail,
    isExistingTeamMember
  }) => {
    const comdynInviteFunction = httpsCallable(functions, 'cdInvite');
    const result = await comdynInviteFunction({
      teamId,
      teamMemberId,
      teamName,
      conversationId,
      conversationTitle,
      recipientName,
      recipientEmail,
      isExistingTeamMember
    });

    return result?.data;
  };

  const acceptUserInvitation = async userInvitation => {};
  const declineUserInvitation = async userInvitation => {};

  const resendInvitation = async invitationId => {
    const comdynReSendInviteFunc = httpsCallable(functions, 'cdReSendInvite');
    await comdynReSendInviteFunc({
      invitationId
    });
  };

  const revokeInvitations = async ({ teamMemberId, conversationId }) => {
    const comdynInvitationRevocationFunc = httpsCallable(
      functions,
      'cdRevokeInvitations'
    );
    await comdynInvitationRevocationFunc({
      teamMemberId,
      conversationId
    });
  };

  return {
    createInvitation,
    userInvitations,
    acceptUserInvitation,
    declineUserInvitation,
    revokeInvitations,
    resendInvitation
  };
};

export default useInvitations;
