import React from 'react';
import { HeroIcon } from '../../icons/HeroIcon';
import clsx from 'clsx';
import { XCircleIcon } from '@heroicons/react/24/outline';

const IconOrImage = props => {
  const { icon, iconColor, image } = props;

  if (!icon && !image) {
    return null;
  }

  return (
    <div className="lg:mr-3">
      {icon ? (
        <div style={{ width: 20 }} className="mr-4">
          <HeroIcon
            icon={icon}
            className={clsx(
              `text-${iconColor} stroke-${iconColor} fill-current`
            )}
            size={6}
            outline
          />
        </div>
      ) : null}
      {!icon && image ? (
        <img className="h-6 w-6 -mt-1 mr-4" src={image} alt="" />
      ) : null}
    </div>
  );
};

const ContentWidget = props => {
  const {
    icon,
    image,
    iconColor = 'cd-red',
    textColor = 'cd-navy',
    style = {},

    titleBasis = 'basis-1/2',
    headerContentBasis = 'basis-1/2',
    alignTitle = 'left',
    backgroundColor = 'white',
    hideTitleBorder = false,
    margin = true,
    rounded = true,
    titleFont = 'merriweather',
    titleFontWeight = 'bold',
    onClose
  } = props;
  return (
    <article
      className={clsx(
        'flex flex-col items-start h-auto',
        `bg-${backgroundColor}`,
        margin ? 'm-1' : '',
        rounded ? 'rounded-lg' : '',
        'border-1 border-cd-navy',
        'p-8',
        'w-full'
      )}
      style={style}>
      <div
        className={clsx(
          !hideTitleBorder ? 'border-b border-gray-200' : '',
          'flex min-w-full items-center mb-1 pb-2'
        )}>
        <div className="lg:block hidden">
          <IconOrImage icon={icon} image={image} iconColor={iconColor} />
        </div>

        <div
          className={clsx(
            'flex flex-col lg:flex-row items-center min-w-full',
            `text-${alignTitle} justify-${alignTitle} lg:text-left lg:justify-left `
          )}>
          <div
            className={clsx(
              `font-${titleFont} font-${titleFontWeight}`,
              `text-${textColor}`,
              `flex items-center`,
              props.headerContent ? titleBasis : 'no-title'
            )}
            style={{ fontSize: 22 }}>
            <span className="lg:hidden">
              <IconOrImage icon={icon} image={image} iconColor={iconColor} />
            </span>
            <div className="flex flex-col">
              {props.title}
              {props.subtitle ? (
                <div className="text-sm font-medium font-sans my-1">
                  {props.subtitle}
                </div>
              ) : null}
            </div>
          </div>

          {props.headerContent || onClose ? (
            <div
              className={clsx(
                'flex justify-end items-center',
                headerContentBasis
              )}>
              {props.headerContent ? <>{props.headerContent}</> : null}
              {onClose ? (
                <XCircleIcon
                  className="h-6 w-6 text-gray-600 cursor-pointer hover:text-cd-red"
                  aria-hidden="true"
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>

      {props.children}
    </article>
  );
};

export default ContentWidget;
