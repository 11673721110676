import React from 'react';
import clsx from 'clsx';
import calendarIcon from '../../../assets/icons/calendar.svg';
import calendarIconWhite from '../../../assets/icons/calendar-white.svg';

const DynamicsList = ({ selectedDynamic, setSelectedDynamic, dynamics }) => {
  return (
    <div className="min-w-full border-l-2 border-gray-200">
      <div className="flex flex-col mx-auto pt-4 flex pl-4">
        <div className="font-merriweather text-2xl font-bold ">
          My User Dynamics
        </div>
        <div className="font-sans font-md">
          Communicator Dynamic® tests you have taken.
        </div>
      </div>

      <div className="flex flex-col justify-center items-center pt-1 mt-0 overflow-hidden items-center min-w-full">
        {dynamics.map((dynamic, index) => (
          <div
            className={clsx(
              selectedDynamic?.id === dynamic.id
                ? 'bg-cd-green text-white'
                : 'bg-white',
              'flex hover:bg-cd-green hover:text-white w-full px-4 py-10 cursor-pointer'
            )}
            onClick={() => setSelectedDynamic(dynamic)}
            key={index}>
            <div className="flex flex-col">
              <span className="font-sans text-md">{dynamic.archetype}</span>
              <div className="flex mt-1">
                {selectedDynamic?.id === dynamic.id ? (
                  <img
                    src={calendarIconWhite}
                    alt=""
                    width={10}
                    className="mr-2"
                  />
                ) : (
                  <img src={calendarIcon} alt="" width={10} className="mr-2" />
                )}
                <span
                  className={clsx(
                    'text-xs',
                    selectedDynamic?.id === dynamic.id ? 'text-gray-200' : ''
                  )}>
                  {dynamic.date}
                </span>
              </div>
              <div
                className={clsx(
                  'text-xs mt-1',
                  selectedDynamic?.id === dynamic.id ? 'text-gray-200' : ''
                )}>
                A <b>{dynamic.setting.toLowerCase()}</b> conversation with{' '}
                <b>{dynamic.with}</b> about <b>{dynamic.subject}</b>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DynamicsList;
