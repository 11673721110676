import $ from 'jquery';

import welcomeIcon from '../../../assets/icons/welcome.png';
import welcomeIconActive from '../../../assets/icons/welcome-active.png';
import myDynamicsIcon from '../../../assets/icons/my-dynamics.png';
import myDynamicsIconActive from '../../../assets/icons/my-dynamics-active.png';
import teamDynamicsIcon from '../../../assets/icons/team-dynamics.png';
import teamDynamicsIconActive from '../../../assets/icons/team-dynamics-active.png';
import goalsIcon from '../../../assets/icons/goals.png';
import goalsIconActive from '../../../assets/icons/goals-active.png';
import shortTestIcon from '../../../assets/icons/short-test.png';
import shortTestIconActive from '../../../assets/icons/short-test-active.png';
import fullTestIcon from '../../../assets/icons/full-test.png';
import fullTestIconActive from '../../../assets/icons/full-test-active.png';
import workshopsIcon from '../../../assets/icons/workshops.png';
import workshopsIconActive from '../../../assets/icons/workshops-active.png';
import coursesIcon from '../../../assets/icons/courses.png';
import coursesIconActive from '../../../assets/icons/courses-active.png';
import coachesIcon from '../../../assets/icons/find-a-coach.png';
import coachesIconActive from '../../../assets/icons/find-a-coach-active.png';
import faqIcon from '../../../assets/icons/faq.png';
import faqIconActive from '../../../assets/icons/faq-active.png';
import contactIcon from '../../../assets/icons/contact.png';
import contactIconActive from '../../../assets/icons/contact-active.png';

const PrimaryNav = [
  {
    path: '/',
    name: 'Welcome',
    hideInSidebar: false,
    defaultSidebarItem: true,
    icon: welcomeIcon,
    iconActive: welcomeIconActive,
    sortOrder: 0,
    showDividerAfter: false,
    preventDispatch: false
  },
  {
    path: '/dynamics',
    name: 'My Dynamics',
    hideInSidebar: false,
    defaultSidebarItem: false,
    icon: myDynamicsIcon,
    iconActive: myDynamicsIconActive,
    sortOrder: 0,
    showDividerAfter: false,
    preventDispatch: false
  },
  {
    path: '/team',
    name: 'Team Dynamics',
    hideInSidebar: false,
    defaultSidebarItem: false,
    icon: teamDynamicsIcon,
    iconActive: teamDynamicsIconActive,
    sortOrder: 0,
    showDividerAfter: false,
    preventDispatch: false
  },
  {
    path: '/goals',
    name: 'My Goals',
    hideInSidebar: false,
    defaultSidebarItem: false,
    icon: goalsIcon,
    iconActive: goalsIconActive,
    sortOrder: 0,
    showDividerAfter: true,
    preventDispatch: false
  },
  {
    path: '/mini-test',
    heading: 'TAKE THE:',
    name: 'Short Test',
    hideInSidebar: false,
    defaultSidebarItem: false,
    icon: shortTestIcon,
    iconActive: shortTestIconActive,
    sortOrder: 0,
    showDividerAfter: false,
    preventDispatch: false
  },
  {
    path: '/full-test',
    name: 'Full Test',
    hideInSidebar: false,
    defaultSidebarItem: false,
    icon: fullTestIcon,
    iconActive: fullTestIconActive,
    sortOrder: 0,
    showDividerAfter: true,
    preventDispatch: false
  },
  {
    path: '/workshops',
    name: 'Workshops',
    hideInSidebar: false,
    defaultSidebarItem: false,
    icon: workshopsIcon,
    iconActive: workshopsIconActive,
    sortOrder: 0,
    showDividerAfter: false,
    preventDispatch: false
  },
  {
    path: '/courses',
    name: 'All Courses',
    hideInSidebar: false,
    defaultSidebarItem: false,
    icon: coursesIcon,
    iconActive: coursesIconActive,
    sortOrder: 0,
    showDividerAfter: false,
    preventDispatch: false
  },
  {
    path: '/coaches',
    name: 'Find a Coach',
    hideInSidebar: false,
    defaultSidebarItem: false,
    icon: coachesIcon,
    iconActive: coachesIconActive,
    sortOrder: 0,
    showDividerAfter: true,
    preventDispatch: false
  },
  {
    path: '/faq',
    name: 'FAQ',
    hideInSidebar: false,
    defaultSidebarItem: false,
    icon: faqIcon,
    iconActive: faqIconActive,
    sortOrder: 0,
    showDividerAfter: false,
    preventDispatch: true,
    version: '1.26.0',
    onClick: () => {
      $('#crispChatOpener').click();
    }
  },
  {
    path: '/contact-us',
    name: 'Contact',
    hideInSidebar: false,
    defaultSidebarItem: false,
    icon: contactIcon,
    iconActive: contactIconActive,
    sortOrder: 0,
    showDividerAfter: false,
    preventDispatch: true,
    version: '1.26.0',
    onClick: () => {
      $('#crispChatOpener').click();
    }
  }
];

export default PrimaryNav;
