import React from 'react';

const MyProfile = () => {
  return (
    <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      My Profile
    </div>
  );
};

export default MyProfile;
