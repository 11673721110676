import { TEAMS_ACTIONS } from '../../actions/teams';

const initialState = {
  teams: []
};

const TeamReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEAMS_ACTIONS.TEAMS_LOADED:
      return {
        ...state,
        teams: action.payload
      };
    case 'INVALID_USER':
      return initialState;
    case 'USER_LOGOUT':
      return initialState;

    default:
      return state;
  }
};

export default TeamReducer;
