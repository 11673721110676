import { useDispatch, useSelector } from 'react-redux';

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator
} from 'firebase/functions';

import {
  ASSESSMENT_STEPS,
  ASSESSMENT_STEP_ORDER
} from '../../components/Assessment/Steps';

import { dynamicRefreshedAction } from '../../redux/actions/dynamics';

import {
  ASSESSMENT_DATA_KEYS,
  nextStepAction,
  setContextDataAction,
  previousStepAction,
  initialiseAction,
  startAssessmentAction,
  nextQuestionAction
} from '../../redux/actions/assessment';

/*
    Hook which provides services to deliver the questions and receive answer submissions for an assessments
*/
const useAssessment = () => {
  const dispatch = useDispatch();
  const functions = getFunctions();
  const { document, question, context, step } = useSelector(
    state => state.Assessment
  );
  const user = useSelector(state => state.User);

  if (process.env.NODE_ENV === 'development') {
    //console.log('useCloud running against emulators on 5001...');
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }

  const start = async () => {
    const comdynStartFunction = httpsCallable(functions, 'cdStart');
    const result = await comdynStartFunction(context);
    dispatch(startAssessmentAction(result?.data));
  };

  const answer = async answer => {
    const comdynAnswerFunction = httpsCallable(functions, 'cdAnswer');
    const result = await comdynAnswerFunction({
      userDynamicId: document.id,
      questionId: question.id,
      answer
    });
    if (result?.data?.question?.id) {
      dispatch(nextQuestionAction(result.data.question));
    } else {
      if (result.data.assessment) {
        await dispatch(dynamicRefreshedAction(result.data.assessment));
      }
      await nextStep();
    }
  };

  const answerYes = async () => {
    await answer('agree');
  };

  const answerNo = async () => {
    await answer('disagree');
  };

  const initialiseNewAssessment = async () => {
    dispatch(initialiseAction(user.firstName));
  };

  const nextStep = async () => {
    if (step) {
      const stepIndex = ASSESSMENT_STEP_ORDER.findIndex(s => s === step);
      if (
        stepIndex > -1 &&
        ASSESSMENT_STEP_ORDER[stepIndex + 1] === ASSESSMENT_STEPS.QUESTION
      ) {
        // The next step is where we are asking our questions, so we need to start a new assessment
        await start();
      }
    }
    dispatch(nextStepAction());
  };

  const previousStep = async () => {
    dispatch(previousStepAction());
  };

  const setGender = async value => {
    dispatch(setContextDataAction(ASSESSMENT_DATA_KEYS.GENDER, value));
  };

  const setName = async value => {
    dispatch(setContextDataAction(ASSESSMENT_DATA_KEYS.NAME, value));
  };

  const setPronouns = async value => {
    dispatch(setContextDataAction(ASSESSMENT_DATA_KEYS.PRONOUNS, value));
  };

  const setSetting = async value => {
    dispatch(setContextDataAction(ASSESSMENT_DATA_KEYS.SETTING, value));
  };
  const setSubject = async value => {
    dispatch(setContextDataAction(ASSESSMENT_DATA_KEYS.SUBJECT, value));
  };

  const setWith = async value => {
    dispatch(setContextDataAction(ASSESSMENT_DATA_KEYS.WITH, value));
  };

  return {
    initialiseNewAssessment,
    nextStep,
    previousStep,
    setGender,
    setName,
    setPronouns,
    setSetting,
    setSubject,
    setWith,
    answerYes,
    answerNo,
    step,
    question,
    context
  };
};

export default useAssessment;
