import React from 'react';
import Header from '../Header';
import Navigation from '../Navigation';
import clsx from 'clsx';

const Layout = props => {
  const { title, section, sticky, stickyLeft, stickyBg = 'bg-white' } = props;

  return (
    <>
      <Header />
      <div className="relative flex justify-center w-full">
        <div className="hidden lg:relative lg:block lg:flex-none">
          <div className="absolute inset-y-0 right-0 w-[50vw] bg-slate-50 dark:hidden" />
          <div className="absolute bottom-0 right-0 top-16 hidden h-12 w-px bg-gradient-to-t from-slate-800 dark:block" />
          <div className="absolute bottom-0 right-0 top-28 hidden w-px bg-slate-800 dark:block" />
          <div className="sticky top-[4rem] -ml-0.5 h-[calc(100vh-4.5rem)] overflow-y-auto overflow-x-hidden py-8 bg-white pl-3">
            <Navigation />
          </div>
        </div>
        {stickyLeft && (
          <div
            className={clsx(
              `lg:ml-2 ${stickyBg} min-w-[15%] flex hidden xl:sticky xl:top-[4.5rem] xl:-mr-6 xl:block xl:h-[calc(100vh-4.5rem)] xl:flex-none xl:overflow-y-auto xl:py-16 xl:pr-6`
            )}>
            {stickyLeft}
          </div>
        )}
        <div className="min-w-0 max-w-2xl flex-auto lg:max-w-none lg:px-4 lg:mt-4 ">
          <article className="pt-4">
            {(title || section) && (
              <header className="mb-9 space-y-1">
                {section && (
                  <p className="font-display text-sm font-medium text-sky-500">
                    {section}
                  </p>
                )}
                {title && (
                  <h1 className="font-display text-3xl tracking-tight text-slate-900 dark:text-white">
                    {title}
                  </h1>
                )}
              </header>
            )}
            {props.children}
          </article>
        </div>
        {sticky && (
          <div className="lg:ml-2 bg-white min-w-[20%] flex hidden xl:sticky xl:top-[4.5rem] xl:-mr-6 xl:block xl:h-[calc(100vh-4.5rem)] xl:flex-none xl:overflow-y-auto xl:py-16 xl:pr-6">
            {sticky}
          </div>
        )}
      </div>
    </>
  );
};

export default Layout;
