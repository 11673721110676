const ASSESSMENT_ACTIONS = {
  INITIALISE: 'INITIALISE',
  SET_CONTEXT_DATA: 'SET_CONTEXT_DATA',
  NEXT_STEP: 'NEXT_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  START: 'START',
  NEXT_QUESTION: 'NEXT_QUESTION'
};

const ASSESSMENT_DATA_KEYS = {
  NAME: 'name',
  WITH: 'with',
  GENDER: 'gender',
  PRONOUNS: 'genderPronouns',
  SUBJECT: 'subject',
  SETTING: 'setting',
  QUESTION: 'QUESTION',
  ANSWER: 'ANSWER',
  RESULT: 'RESULT'
};

const initialiseAction = name => {
  return {
    type: ASSESSMENT_ACTIONS.INITIALISE,
    payload: { name }
  };
};

const nextStepAction = () => {
  return {
    type: ASSESSMENT_ACTIONS.NEXT_STEP,
    payload: {}
  };
};

const previousStepAction = () => {
  return {
    type: ASSESSMENT_ACTIONS.PREVIOUS_STEP,
    payload: {}
  };
};

const setContextDataAction = (key, value) => {
  return {
    type: ASSESSMENT_ACTIONS.SET_CONTEXT_DATA,
    payload: { key, value }
  };
};
/* An assessment is started when we have gathered the preliminary information and we want to start asking the questions */
const startAssessmentAction = data => {
  return {
    type: ASSESSMENT_ACTIONS.START,
    payload: data
  };
};

const nextQuestionAction = question => {
  return {
    type: ASSESSMENT_ACTIONS.NEXT_QUESTION,
    payload: question
  };
};

export {
  ASSESSMENT_ACTIONS,
  ASSESSMENT_DATA_KEYS,
  initialiseAction,
  setContextDataAction,
  nextStepAction,
  previousStepAction,
  startAssessmentAction,
  nextQuestionAction
};
