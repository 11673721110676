import { DYNAMICS_ACTIONS } from '../../actions/dynamics';

const initialState = {
  dynamics: []
};

const DynamicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGOUT' || 'INVALID_USER':
      return initialState;

    case DYNAMICS_ACTIONS.DYNAMIC_REFRESHED:
      const index = state.dynamics.findIndex(d => d.id === action.payload.id);
      if (index > -1) {
        const newDynamics = [...state.dynamics];
        newDynamics[index] = action.payload;
        return {
          ...state,
          dynamics: newDynamics
        };
      }
      return {
        ...state,
        dynamics: action.payload
      };

    case DYNAMICS_ACTIONS.DYNAMICS_LOADED:
      return {
        ...state,
        dynamics: action.payload
      };

    default:
      return state;
  }
};

export default DynamicsReducer;
