import { useDispatch, useSelector } from 'react-redux';

import { teamsLoadedAction } from '../../redux/actions/teams';
import { useEffect, useState } from 'react';

import { db, serverTimestamp } from '../../firebase';

import {
  collection,
  onSnapshot,
  orderBy,
  query,
  getDocs,
  addDoc,
  setDoc,
  doc,
  where
} from 'firebase/firestore';

import useInvitations from '../invitations';

/*
    Hook which provides team management to the current user
*/
const useTeams = () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const { createInvitation, revokeInvitation } = useInvitations();
  const user = useSelector(state => state.User);
  const { teams } = useSelector(state => state.Teams);

  useEffect(() => {
    // let unsubTeams = () => {};
    if (user?.validUser && !loaded) {
      // unsubTeams = watchTeams();
      watchTeams();
      setLoaded(true);
    }
    return () => {
      try {
        // unsubTeams();
      } catch {}
    };
  }, [user]);

  const watchTeams = () => {
    const q = query(
      collection(db, `users/${user.userID}/cd_teams`),
      orderBy('name')
    );

    return onSnapshot(q, async snapshot => {
      const teams = [];

      snapshot.forEach(async doc => {
        const team = doc.data();
        team.members = [];
        team.id = doc.id;
        if (!team?.deleted) {
          teams.push(team);
        }
      });

      await Promise.all(
        teams.map(async team => {
          /* load the members */
          const q = query(
            collection(
              db,
              `users/${user.userID}/cd_teams/${team.id}/cd_team_members`
            ),
            where('deleted', '==', false)
          );

          const membersSnapshot = await getDocs(q);

          membersSnapshot.forEach(doc => {
            const teamMember = doc.data();
            if (!teamMember?.deleted) {
              team.members.push({
                id: doc.id,
                ...doc.data()
              });
            }
          });
        })
      );

      dispatch(teamsLoadedAction(teams));
    });
  };

  const createTeam = async teamName => {
    const collectionRef = collection(db, `users/${user.userID}/cd_teams`);

    const defDoc = {};

    defDoc.name = teamName;
    defDoc.userID = user.userID;
    defDoc.deleted = false;
    defDoc.description = '';
    defDoc.sortOrder = 1;
    defDoc.createdAt = serverTimestamp();
    defDoc.updatedAt = serverTimestamp();
    delete defDoc.id;

    const docRef = await addDoc(collectionRef, defDoc);
    await setDoc(
      doc(db, `users/${user.userID}/cd_teams`, docRef.id),
      { id: docRef.id },
      { merge: true }
    );
  };

  const addTeamMember = async ({
    firstName,
    lastName,
    email,
    jobTitle,
    department,
    notes,
    teamId
  }) => {
    const collectionRef = collection(
      db,
      `users/${user.userID}/cd_teams/${teamId}/cd_team_members`
    );

    const defDoc = {};

    defDoc.firstName = firstName;
    defDoc.lastName = lastName;
    defDoc.email = email;
    defDoc.teamId = teamId;
    defDoc.jobTitle = jobTitle || '';
    defDoc.department = department || '';
    defDoc.status = 'sending'; // means invite email not yet sent
    defDoc.notes = notes || '';
    defDoc.deleted = false;
    defDoc.sortOrder = 1;
    defDoc.createdAt = serverTimestamp();
    defDoc.updatedAt = serverTimestamp();
    delete defDoc.id;

    const newTeamMemberDocRef = await addDoc(collectionRef, defDoc);
    await setDoc(
      doc(
        db,
        `users/${user.userID}/cd_teams/${teamId}/cd_team_members`,
        newTeamMemberDocRef.id
      ),
      { id: newTeamMemberDocRef.id },
      { merge: true }
    );

    const team = teams.find(t => t.id === teamId);
    await createInvitation({
      teamId,
      teamMemberId: newTeamMemberDocRef.id,
      teamName: team.name,
      recipientEmail: email,
      recipientName: `${firstName} ${lastName}`,
      isExistingTeamMember: teams.some(
        t => t.id !== teamId && t.members.some(m => m.email === email)
      )
    });

    // Touch the team to force a snapshot update
    await setDoc(
      doc(db, `users/${user.userID}/cd_teams`, teamId),
      { updatedAt: serverTimestamp() },
      { merge: true }
    );
  };

  const updateTeamMember = async ({
    firstName,
    lastName,
    email,
    jobTitle,
    department,
    notes,
    memberId,
    teamId
  }) => {
    const defDoc = {};

    defDoc.firstName = firstName;
    defDoc.lastName = lastName;
    defDoc.email = email;
    defDoc.jobTitle = jobTitle;
    defDoc.department = department;
    defDoc.notes = notes;
    defDoc.updatedAt = serverTimestamp();

    setDoc(
      doc(
        db,
        `users/${user.userID}/cd_teams/${teamId}/cd_team_members`,
        memberId
      ),
      document
    );
  };

  const removeTeamMember = async ({ memberId, teamId }) => {
    setDoc(
      doc(
        db,
        `users/${user.userID}/cd_teams/${teamId}/cd_team_members`,
        memberId
      ),
      {
        deleted: true,
        updatedAt: serverTimestamp()
      },
      { merge: true }
    );

    // revoke any invitations for this team member
    await revokeInvitation({ teamMemberId: memberId });

    // Touch the team to force a snapshot update
    await setDoc(
      doc(db, `users/${user.userID}/cd_teams`, teamId),
      {
        updatedAt: serverTimestamp()
      },
      { merge: true }
    );
  };

  return {
    teams,
    addTeamMember,
    updateTeamMember,
    createTeam,
    removeTeamMember
  };
};

export default useTeams;
