import { useEffect, useState } from 'react';
import { auth } from '../../firebase';
import { useDispatch } from 'react-redux';
import {
  checkActionCode,
  applyActionCode,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  sendEmailVerification,
  signOut
} from 'firebase/auth';
import { useSearchParams } from 'react-router-dom';
import CompanyLogo from '../../assets/company-logo.png';
import Version from '../../version';
import NewPassword from '../NewPassword';
import ForgotPassword from '../ForgotPassword';
import Button from '../../components/Button';
import useUser from '../../hooks/user';

const FirebaseHandler = () => {
  /* https://firebase.google.com/docs/auth/custom-email-handler */

  const dispatch = useDispatch();
  const { recordLastLogin } = useUser();

  const UI_STATE = {
    EMAIL_VERIFIED: 'EMAIL_VERIFIED',
    EMAIL_VERIFICATION_LINK_RESENT: 'EMAIL_VERIFICATION_LINK_RESENT',
    EMAIL_VERIFICATION_LINK_INVALID_OR_EXPIRED:
      'EMAIL_LINK_VERIFICATION_INVALID_OR_EXPIRED',
    PASSWORD_RESET_READY: 'PASSWORD_RESET_READY',
    PASSWORD_RESETCODE_EXPIRED: 'PASSWORD_RESETCODE_EXPIRED',
    PASSWORD_RESET_FAILED: 'PASSWORD_RESET_FAILED',
    PASSWORD_SUCCESSFULLY_RESET: 'PASSWORD_SUCCESSFULLY_RESET'
  };

  const [uiState, setUiState] = useState();
  const [passwordResetInfo, setPasswordResetInfo] = useState();

  const [params] = useSearchParams();
  const [messaging, setMessaging] = useState();

  // Get the action to complete.
  const mode = params.get('mode');
  // Get the one-time code from the query parameter.
  const actionCode = params.get('oobCode');
  // (Optional) Get the continue URL from the query parameter if available.
  const continueUrl = params.get('continueUrl');
  // (Optional) Get the language code if available.
  const lang = params.get('lang') || 'en';

  const handleResetPassword = (auth, actionCode, continueUrl, lang) => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, actionCode)
      .then(email => {
        // TODO: Show the reset screen with the user's email and ask the user for
        setUiState(UI_STATE.PASSWORD_RESET_READY);
        setPasswordResetInfo({ email, actionCode });
      })
      .catch(error => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        setUiState(UI_STATE.PASSWORD_RESETCODE_EXPIRED);
      });
  };

  const handleVerifyEmail = (auth, actionCode, continueUrl, lang) => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    applyActionCode(auth, actionCode)
      .then(resp => {
        setUiState(UI_STATE.EMAIL_VERIFIED);
        dispatch({ type: 'USER_EMAIL_VERIFIED', payload: {} });
        return true;
        // Email address has been verified.
        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch(error => {
        let currentUser;

        try {
          currentUser = auth.getUser();
        } catch {
          //
        }

        if (currentUser) {
          sendEmailVerification(auth, currentUser.email)
            .then(async () => {
              // update the user to trigger a snapshot refresh of the page if open in other browsers
              await recordLastLogin(currentUser.uid);
              setUiState(UI_STATE.EMAIL_VERIFICATION_LINK_RESENT);
            })
            .catch(() => {
              setUiState(UI_STATE.EMAIL_VERIFICATION_LINK_INVALID_OR_EXPIRED);
              // Make sure they are signed out
              signOut(auth)
                .then(() => {
                  // Sign-out successful.
                })
                .catch(error => {
                  console.log(error);
                });
            });
        } else {
          setUiState(UI_STATE.EMAIL_VERIFICATION_LINK_INVALID_OR_EXPIRED);
          signOut(auth)
            .then(() => {
              // Sign-out successful.
            })
            .catch(error => {
              console.log(error);
            });
        }

        return false;
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
      });
  };

  const handleRecoverEmail = (auth, actionCode, lang) => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail = null;
    // Confirm the action code is valid.
    checkActionCode(auth, actionCode)
      .then(info => {
        // Get the restored email address.
        restoredEmail = info['data']['email'];

        // Revert to the old email.
        return applyActionCode(auth, actionCode);
      })
      .then(() => {
        // Account email reverted to restoredEmail

        // TODO: Display a confirmation message to the user.

        // You might also want to give the user the option to reset their password
        // in case the account was compromised:
        sendPasswordResetEmail(auth, restoredEmail)
          .then(() => {
            // Password reset confirmation sent. Ask user to check their email.
          })
          .catch(error => {
            // Error encountered while sending password reset code.
          });
      })
      .catch(error => {
        // Invalid code.
      });
  };

  useEffect(() => {
    // Handle the user management action.
    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        handleResetPassword(auth, actionCode, continueUrl, lang);
        break;
      case 'recoverEmail':
        // Display email recovery handler and UI.
        handleRecoverEmail(auth, actionCode, lang);
        break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        handleVerifyEmail(auth, actionCode, continueUrl, lang);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    switch (uiState) {
      case UI_STATE.PASSWORD_RESET_READY: {
        setMessaging({
          showNewPassword: true
        });
        break;
      }
      case UI_STATE.PASSWORD_RESETCODE_EXPIRED: {
        setMessaging({
          title: 'Oops. Something is not right!',
          body:
            'The link may have expired. Please restart your password reset attempt again or login if you have already changed your password.',
          showLogin: true
        });
        break;
      }
      case UI_STATE.PASSWORD_RESET_FAILED: {
        setMessaging({
          title: 'Oops. Something is not right!',
          body: 'Your new password might not be valid.',
          showPasswordReset: true
        });
        break;
      }
      case UI_STATE.PASSWORD_SUCCESSFULLY_RESET: {
        setMessaging({
          title: 'Password Reset Successful',
          body:
            'Nice work! Your new password has been successfully reset. You can use it when you next login.',
          showLogin: true
        });
        break;
      }
      case UI_STATE.EMAIL_VERIFICATION_LINK_INVALID_OR_EXPIRED: {
        setMessaging({
          title: 'This verification link has expired.',
          body:
            'This link has expired. Please login again to continue and we will send you another link to verify your email if required.',
          showLogin: true
        });
        break;
      }
      case UI_STATE.EMAIL_VERIFICATION_LINK_RESENT: {
        setMessaging({
          title: 'Check your Inbox',
          body:
            'Your verification link has expired. We have re-sent you another one. Please check your inbox, spam and junk folders.',
          showLogin: true
        });
        break;
      }
      case UI_STATE.EMAIL_VERIFIED: {
        setMessaging({
          title: 'Email Verification Complete.',
          body:
            'Nice job. Your email has been successfully verified. You can now continue. Welcome to Communicator Dynamics®',
          showLogin: true
        });
        break;
      }
      default:
        setMessaging(undefined);
    }
  }, [uiState]);

  if (!messaging) {
    return <></>;
  }

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8  items-center text-center">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {!messaging.showNewPassword ? (
          <>
            <img
              className="mx-auto h-20 w-auto mb-6"
              src={CompanyLogo}
              alt=""
            />
            <h2 className="mt-8 text-center text-3xl font-extrabold text-gray-900 my-8 font-merriweather">
              {messaging.title}
            </h2>
            <p className="mt-2 text-center text-md text-gray-600">
              {messaging.body}
            </p>
          </>
        ) : null}
        {messaging.showLogin ? <Button path="/login" text="Login" /> : null}
        {messaging.showNewPassword ? (
          <NewPassword
            email={passwordResetInfo.email}
            actionCode={passwordResetInfo.actionCode}
          />
        ) : null}
        {messaging.showPasswordReset ? <ForgotPassword /> : null}
      </div>
      <Version />
    </div>
  );
};

export default FirebaseHandler;
