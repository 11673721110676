import Introduction from './Introduction';
import Name from './Name';
import Gender from './Gender';
import Pronouns from './Pronouns';
import Question from './Question';
import Result from './Result';
import Setting from './Setting';
import Subject from './Subject';
import With from './With';

const ASSESSMENT_STEPS = {
  INTRODUCTION: 'INTRODUCTION',
  NAME: 'NAME',
  WITH: 'WITH',
  GENDER: 'GENDER',
  //PRONOUNS: 'PRONOUNS',
  SUBJECT: 'SUBJECT',
  //SETTING: 'SETTING',
  QUESTION: 'QUESTION',
  RESULT: 'RESULT'
};

const ASSESSMENT_STEP_ORDER = [
  ASSESSMENT_STEPS.INTRODUCTION,
  ASSESSMENT_STEPS.NAME,
  ASSESSMENT_STEPS.WITH,
  ASSESSMENT_STEPS.GENDER,
  //ASSESSMENT_STEPS.PRONOUNS,
  ASSESSMENT_STEPS.SUBJECT,
  //ASSESSMENT_STEPS.SETTING,
  ASSESSMENT_STEPS.QUESTION,
  ASSESSMENT_STEPS.RESULT
];

export {
  Introduction,
  Name,
  With,
  Gender,
  Pronouns,
  Subject,
  Setting,
  Question,
  Result,
  ASSESSMENT_STEP_ORDER,
  ASSESSMENT_STEPS
};
