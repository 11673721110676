import React from 'react';

import PrimaryNav from './MenuItems/PrimaryNav';
import MenuItems from './MenuItems';

const Navigation = () => {
  const onSelected = (item, e) => {
    if (item.menu.onClick) {
      e.preventDefault();
      item.menu.onClick();
    }
  };

  const isActiveMenuItem = item => {
    return item.path === window.location.pathname;
  };

  const sideBarItems = PrimaryNav.filter(n => !n.hideInSidebar);

  return (
    <div className="w-64 pr-8 bg-white">
      {sideBarItems?.length > 0 ? (
        <MenuItems
          items={sideBarItems}
          onSelected={onSelected}
          isActiveMenuItem={isActiveMenuItem}
        />
      ) : (
        <div>Please wait...</div>
      )}
    </div>
  );
};

export default Navigation;
