import React from 'react';

import Facet from './Facet';
import ContentWidget from '../ContentWidget';
import Button from '../Button';

const DynamicBreakdown = props => {
  const { dynamic, hideTitle } = props;

  if (!dynamic) {
    return null;
  }

  return (
    <ContentWidget
      title={hideTitle ? '' : `You as the ${dynamic.archetype}`}
      subtitle="Each aspect of your Communicator Dynamic is shown below. Click any of the circles to discover more.">
      <div className="flex flex-col min-w-full p-4 overflow-hidden">
        {dynamic?.facets.map((facet, index) => (
          <Facet key={index} facet={facet} />
        ))}
      </div>
      {dynamic.type === 'mini' ? (
        <>
          <div className="flex flex-col min-w-full p-4 overflow-hidden -mt-4">
            <div className="blur-sm">
              {dynamic?.facets.map((facet, index) => (
                <Facet key={index} facet={facet} />
              ))}
            </div>
            <div className="flex flex-col items-center px-4 py-6 mx-2 lg:mx-32 bg-white absolute mt-14 rounded max-w-md z-20">
              <span className="font-merriweather font-bold text-xl text-center">
                Gain deeper insights into this conversation by Taking the Full
                Test
              </span>
              <Button text="TAKE THE FULL TEST" path="/full-test" />
            </div>
          </div>
        </>
      ) : null}
    </ContentWidget>
  );
};

export default DynamicBreakdown;
