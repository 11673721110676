import React from 'react';
import CompanyLogo from '../assets/company-logo.png';

const LoadingSpinner = ({
  spinnerHeight = 14,
  height,
  showLogo = true,
  footertext = ''
}) => {
  const classNames = height ? 'flex' : 'flex h-screen';

  const style = {};

  if (height) {
    style.height = parseInt(height);
  }
  return (
    <>
      <div className={classNames} style={style} data-testid="loadingSpinner">
        <div className="m-auto w-full">
          <div className="flex justify-center items-center mt-8">
            <div
              className={`animate-spin rounded-full h-${spinnerHeight} w-${spinnerHeight} border-b-2 border-gray-900`}></div>
          </div>
          {showLogo && (
            <img
              className="mx-auto mb-6 mt-6"
              style={{ width: 150 }}
              src={CompanyLogo}
              alt=""
            />
          )}
          {footertext.length > 0 && (
            <div className="text-sm w-full text-center mt-4 text-gray-600">
              {footertext}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoadingSpinner;
