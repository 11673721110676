/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

const AddConversationModal = props => {
  const { open, setOpen, onConversationCreated } = props;

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  const updateTitle = e => {
    setTitle(e.target.value);
  };
  const updateDescription = e => {
    setDescription(e.target.value);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left min-w-[85%]">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-gray-900 font-sans font-bold">
                      Create a new Conversation
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-700">
                        A conversation that your staff take part in for which
                        you want to gain insights into their Communicator
                        Dynamics®, compare and receive recommendations on how
                        individual participants of the conversation can improve
                        their communication to create better outcomes.
                      </p>
                      <p className="text-sm text-gray-700 mt-1">
                        Once your conversation is created, you can invite a
                        whole, individual team members of members spanning
                        multiple teams, to the conversation.
                      </p>
                    </div>
                    <div className="mt-3">
                      <input
                        type="text"
                        onChange={updateTitle}
                        name="title"
                        id="title"
                        className="min-w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Conversation Title i.e. Handling a Customer Complaint"
                      />
                    </div>
                    <div className="mt-3">
                      <input
                        type="text"
                        onChange={updateDescription}
                        name="description"
                        id="description"
                        className="min-w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Conversation Description"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 mr-3 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={clsx(
                    title && description
                      ? 'bg-green-600'
                      : 'bg-gray-500 cursor-default hover:bg-gray-500',
                    'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white hover:bg-green-700 sm:ml-3 sm:w-auto sm:text-sm'
                  )}
                  onClick={() => {
                    if (title && description) {
                      onConversationCreated({ title, description });
                    }
                  }}>
                  Create Conversation
                </button>

                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddConversationModal;
