import React from 'react';
import facetOnIcon from '../../../assets/icons/facet-on.svg';
import facetOffIcon from '../../../assets/icons/facet-off.svg';

const FacetOption = ({ option, selected }) => {
  return (
    <div className="flex flex-col items-center flex-grow cursor-pointer z-10 group">
      <img
        src={selected ? facetOnIcon : facetOffIcon}
        alt=""
        className="hover:animation-pulse"
      />
      <span className="text-sm -mt-6 text-navy hover:font-bold">
        {option.name}
      </span>
    </div>
  );
};

export default FacetOption;
