const USER_ACTIONS = {
  USER_EMAIL_VERIFIED: 'USER_EMAIL_VERIFIED',
  USER_LOADED: 'USER_LOADED',
  INVALID_USER: 'INVALID_USER',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_STATS_LOADED: 'USER_STATS_LOADED',
  USER_INVITATIONS_LOADED: 'USER_INVITATIONS_LOADED'
};

const userInvitationsLoaded = invitations => {
  return {
    type: USER_ACTIONS.USER_INVITATIONS_LOADED,
    payload: invitations
  };
};

export { USER_ACTIONS, userInvitationsLoaded };
