import React from 'react';
import useInvitations from '../../../hooks/invitations';

import Button from '../../../components/Button';

const Invitation = () => {
  const { acceptTeamInvitation, userInvitations } = useInvitations();

  if (!userInvitations || userInvitations?.length === 0) {
    return;
  }

  return (
    <div className="flex flex-col justify-center items-center my-2">
      {userInvitations.map(userInvitation => (
        <div className="flex flex-col bg-cd-yellow items-center text-center rounded-lg my-2 lg:px-32 lg:py-4">
          <div className="text-md font-sans p-4 text-center text-gray-700 ">
            <>
              {userInvitation.conversationTitle?.trim().length > 0 ? (
                <>
                  <b>{userInvitation.inviterName}</b>
                  {userInvitation.organisation?.trim().length > 0
                    ? ` <b>of ${userInvitation.organisation}</b>`
                    : ''}{' '}
                  has invited you to take a Communicator Dynamics® test for the
                  conversation <b>{userInvitation.conversationTitle}</b> in team{' '}
                  <b>{userInvitation.teamName}</b>
                </>
              ) : (
                <>
                  <b>{userInvitation.inviterName}</b>
                  <b>
                    {userInvitation.organisation?.trim().length > 0
                      ? ` of ${userInvitation.organisation}`
                      : ''}
                  </b>{' '}
                  has invited you to take join team{' '}
                  <b>{userInvitation.teamName}</b>. When you accept their
                  invitation, you will receive invites to take the Communicator
                  Dynamics® test for any conversations they create, which will
                  appear here on your dashboard.
                </>
              )}
            </>
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className="mr-2">
              <Button
                outline
                grey
                onClick={() => acceptTeamInvitation(userInvitations)}
                text="DECLINE"
              />
            </div>
            {userInvitation.conversationTitle?.trim().length > 0 ? (
              <Button
                onClick={() => acceptTeamInvitation(userInvitations)}
                text="START TEST"
              />
            ) : (
              <Button
                green
                onClick={() => acceptTeamInvitation(userInvitations)}
                text="ACCEPT INVITE"
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Invitation;
