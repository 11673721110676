const TEAMS_ACTIONS = {
  TEAMS_LOADED: 'TEAMS_LOADED',
  MEMBER_INVITED: 'MEMBER_INVITED'
};

const teamsLoadedAction = teams => {
  return {
    type: TEAMS_ACTIONS.TEAMS_LOADED,
    payload: teams
  };
};

const teamMemberInvitedAction = invite => {
  return {
    type: TEAMS_ACTIONS.MEMBER_INVITED,
    payload: invite
  };
};

export { TEAMS_ACTIONS, teamsLoadedAction, teamMemberInvitedAction };
