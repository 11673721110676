import React from 'react';
import redStar from '../../../../../assets/icons/red-star.svg';
import greyStar from '../../../../../assets/icons/grey-star.svg';

const Rating = ({ facet }) => {
  const changeRating = rating => {
    // TODO: change rating
  };

  return (
    <div className="flex items-center mx-auto mt-4">
      <div className="flex flex-col" style={{ minWidth: 140 }}>
        <span className="font-merriweather text-xl">{facet.name}</span>
        <span className="font-sans text-sm">{facet.value.name}</span>
      </div>
      <div
        className="grid grid-cols-5 gap-x-4 py-6 text-center px-8"
        style={{ backgroundColor: '#F5F5F6' }}>
        {facet.rating > 0 ? (
          <img
            src={redStar}
            alt=""
            className="cursor-pointer"
            width={40}
            onClick={() => changeRating(1)}
          />
        ) : (
          <img
            src={greyStar}
            alt=""
            className="cursor-pointer"
            width={40}
            onClick={() => changeRating(1)}
          />
        )}
        {facet.rating > 1 ? (
          <img
            src={redStar}
            alt=""
            className="cursor-pointer"
            width={40}
            onClick={() => changeRating(2)}
          />
        ) : (
          <img
            src={greyStar}
            alt=""
            className="cursor-pointer"
            width={40}
            onClick={() => changeRating(2)}
          />
        )}
        {facet.rating > 2 ? (
          <img
            src={redStar}
            alt=""
            className="cursor-pointer"
            width={40}
            onClick={() => changeRating(3)}
          />
        ) : (
          <img
            src={greyStar}
            alt=""
            className="cursor-pointer"
            width={40}
            onClick={() => changeRating(3)}
          />
        )}
        {facet.rating > 3 ? (
          <img
            src={redStar}
            alt=""
            className="cursor-pointer"
            width={40}
            onClick={() => changeRating(4)}
          />
        ) : (
          <img
            src={greyStar}
            alt=""
            className="cursor-pointer"
            width={40}
            onClick={() => changeRating(4)}
          />
        )}
        {facet.rating > 4 ? (
          <img
            src={redStar}
            alt=""
            className="cursor-pointer"
            width={40}
            onClick={() => changeRating(5)}
          />
        ) : (
          <img
            src={greyStar}
            alt=""
            className="cursor-pointer"
            width={40}
            onClick={() => changeRating(6)}
          />
        )}
      </div>
    </div>
  );
};

export default Rating;
