import React from 'react';
import ContentWidget from '../../../components/ContentWidget';
import iconDirector from '../../../assets/icons/icon-director.png';
import Button from '../../../components/Button';

const LatestCourses = () => {
  const courses = [
    {
      title: 'Beginner Communication',
      description: 'In this course you will learn about something cool',
      icon: iconDirector
    },
    {
      title: 'Active Listening',
      description: 'In this course you will learn about something cool',
      icon: iconDirector
    },
    {
      title: 'Positive Questioning',
      description: 'In this course you will learn about something cool',
      icon: iconDirector
    }
  ];

  return (
    <ContentWidget
      title="Latest Courses"
      height={400}
      margin={false}
      rounded={false}>
      <div className="flex flex-col justify-center items-center px-4 mt-2 overflow-hidden">
        {courses.map((course, index) => (
          <div className="flex my-3" key={index}>
            <img src={course.icon} className="h-14 w-14 mr-4" alt="" />
            <div className="flex flex-col">
              <span className="">{course.title}</span>
              <span className="text-xs text-gray-400">
                {course.description}
              </span>
            </div>
          </div>
        ))}
        <Button path="/courses" outline text="VIEW ALL COURSES" />
      </div>
    </ContentWidget>
  );
};

export default LatestCourses;
