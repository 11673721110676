const DYNAMICS_ACTIONS = {
  DYNAMICS_LOADED: 'DYNAMICS_LOADED',
  DYNAMIC_REFRESHED: 'DYNAMIC_REFRESHED'
};

const dynamicsLoadedAction = tests => {
  return {
    type: DYNAMICS_ACTIONS.DYNAMICS_LOADED,
    payload: tests
  };
};

const dynamicRefreshedAction = test => {
  return {
    type: DYNAMICS_ACTIONS.DYNAMIC_REFRESHED,
    payload: test
  };
};

export { DYNAMICS_ACTIONS, dynamicsLoadedAction, dynamicRefreshedAction };
