import React from 'react';
import useAssessment from '../../../../hooks/assessment';
import ContentWidget from '../../../ContentWidget';
import Button from '../../../Button';

const Gender = () => {
  const { setGender, nextStep, context } = useAssessment();

  return (
    <div className="mx-auto max-w-lg">
      <ContentWidget title="What is your Gender?">
        <ul className="grid w-full gap-6 md:grid-cols-2 mt-3">
          <li onClick={() => setGender('female')}>
            <input
              type="radio"
              id="gender-female"
              name="gender"
              value="female"
              defaultChecked={context.gender === 'female'}
              className="hidden peer"
              required
            />
            <label
              htmlFor="gender-female"
              className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
              <div className="block">
                <div className="w-full text-lg font-semibold">Female</div>
              </div>
            </label>
          </li>
          <li onClick={() => setGender('male')}>
            <input
              type="radio"
              id="gender-male"
              name="gender"
              value="male"
              defaultChecked={context.gender === 'male'}
              onClick={() => setGender('male')}
              className="hidden peer"
            />
            <label
              htmlFor="gender-male"
              className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
              <div className="block">
                <div className="w-full text-lg font-semibold">Male</div>
              </div>
            </label>
          </li>
        </ul>
        <div className="flex w-full justify-center">
          <Button
            onClick={() => {
              setGender('male');
              nextStep();
            }}
            text="CONTINUE"
          />
        </div>
      </ContentWidget>
    </div>
  );
};

export default Gender;
