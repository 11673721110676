import React, { useState } from 'react';

import CompanyLogo from '../../assets/company-logo.png';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../firebase';
import { isValidPassword } from '../../utils/Validation';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';

const NewPassword = ({ actionCode }) => {
  const [resetError, setResetError] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const saveNewPassword = async e => {
    e.preventDefault();

    if (!isValidPassword(password)) {
      setResetError({
        message:
          'Please enter a valid password with at least one uppercase letter, one number and one special character.'
      });
      return;
    } else if (confirmPassword !== password) {
      setResetError({
        message: 'Password and confirm password do not match'
      });
      return;
    }

    // Save the new password.
    confirmPasswordReset(auth, actionCode, password)
      .then(resp => {
        // onPasswordResetSuccess();
        setShowSuccess(true);
        // Password reset has been confirmed and new password updated.
        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch(error => {
        console.log(error);
        // onPasswordResetFailed();
        setResetError(error);
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-20 w-auto mb-6" src={CompanyLogo} alt="" />
        <h2 className="mt-8 text-center text-3xl font-extrabold text-gray-900 my-8 font-merriweather">
          Reset Password
        </h2>
        {!showSuccess ? (
          <p className="mt-2 text-center text-md text-gray-600">
            Enter a new password and we will reset it for you.
          </p>
        ) : (
          <>
            <p className="mt-2 text-center text-md text-gray-600">
              Your new password has been successfully changed
            </p>
            <Button path="/login" text="Login" />
          </>
        )}
      </div>

      {!showSuccess && (
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {resetError && (
              <div className="text-red-700 mb-2">{resetError.message}</div>
            )}
            <form className="space-y-6 text-left" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Your New Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                    autoComplete="off"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Confirm Your New Password
                </label>
                <div className="mt-1">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    onChange={e => setConfirmPassword(e.target.value)}
                    autoComplete="off"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <Button onClick={saveNewPassword} text="Save New Password" />
                <Link
                  to="/login"
                  className="text-sm text-activecourse-blue hover:text-activecourse-orange">
                  Back to Login?
                </Link>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default NewPassword;
