import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, onSnapshot, query } from 'firebase/firestore';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator
} from 'firebase/functions';

/*
    Hook which provides data for a Team Member
*/
const useTeamMember = teamMember => {
  const functions = getFunctions();

  if (process.env.NODE_ENV === 'development') {
    //console.log('useCloud running against emulators on 5001...');
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }
  const comdynListTeamMemberDynamics = httpsCallable(
    functions,
    'cdListTeamMemberDynamics'
  );

  const [loaded, setLoaded] = useState(false);

  const [teamMemberDynamics, setTeamMemberDynamics] = useState([]);
  const [dynamicLinks, setDynamicLinks] = useState([]);
  const user = useSelector(state => state.User);

  useEffect(() => {
    async function loadFullDynamics() {
      const fullDynamics = await comdynListTeamMemberDynamics({
        teamMemberUserId: teamMember.teamMemberUserID
      });
      setTeamMemberDynamics(fullDynamics?.data || []);
    }
    if (dynamicLinks?.length > 0) {
      loadFullDynamics();
    } else {
      setTeamMemberDynamics([]);
    }
  }, [dynamicLinks, teamMember]);

  useEffect(() => {
    // let unsubTeamMemberDynamicLinks = () => {};
    if (user?.validUser && !loaded) {
      // unsubTeamMemberDynamicLinks = watchTeamMemberDynamicLinks();
      watchTeamMemberDynamicLinks();
      setLoaded(true);
    }
    return () => {
      try {
        // unsubTeamMemberDynamicLinks();
      } catch {}
    };
  }, [user]);

  const watchTeamMemberDynamicLinks = () => {
    const q = query(
      collection(
        db,
        `users/${user.userID}/cd_teams/${teamMember.teamId}/cd_team_members/${teamMember.id}/cd_team_member_dynamic_links`
      )
    );

    return onSnapshot(q, async snapshot => {
      const userDynamicLinks = [];

      snapshot.forEach(async doc => {
        const dynamicLink = doc.data();
        dynamicLink.id = doc.id;
        if (!dynamicLink?.deleted) {
          userDynamicLinks.push(dynamicLink);
        }
      });

      setDynamicLinks(userDynamicLinks);
    });
  };

  return {
    dynamics: teamMemberDynamics
  };
};

export default useTeamMember;
