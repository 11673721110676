import { useDispatch, useSelector } from 'react-redux';

import { conversationsLoadedAction } from '../../redux/actions/conversations';
import { useEffect, useState } from 'react';

import { db, serverTimestamp } from '../../firebase';

import {
  collection,
  onSnapshot,
  orderBy,
  query,
  getDocs,
  addDoc,
  setDoc,
  doc,
  where
} from 'firebase/firestore';

import useInvitations from '../invitations';

/*
    Hook which provides conversation management to the current user
*/
const useConversations = () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const { createInvitation, revokeInvitations } = useInvitations();
  const user = useSelector(state => state.User);
  const { conversations } = useSelector(state => state.Conversations);

  useEffect(() => {
    //let unsubConversations = () => {};
    if (user?.validUser && !loaded) {
      // unsubConversations = watchConversations();
      watchConversations();
      setLoaded(true);
    }
    return () => {
      try {
        // unsubConversations();
      } catch {}
    };
  }, [user]);

  const watchConversations = () => {
    const q = query(
      collection(db, `users/${user.userID}/cd_conversations`),
      orderBy('title')
    );

    return onSnapshot(q, async snapshot => {
      const conversations = [];

      snapshot.forEach(async doc => {
        const conversation = doc.data();
        conversation.participants = [];
        conversation.id = doc.id;
        if (!conversation?.deleted) {
          conversations.push(conversation);
        }
      });

      await Promise.all(
        conversations.map(async conversation => {
          /* load the members */
          const q = query(
            collection(
              db,
              `users/${user.userID}/cd_conversations/${conversation.id}/cd_conversation_participants`
            ),
            where('deleted', '==', false)
          );

          const participantsSnapshot = await getDocs(q);

          participantsSnapshot.forEach(doc => {
            const participant = doc.data();
            if (!participant?.deleted) {
              conversation.participants.push({
                id: doc.id,
                ...doc.data()
              });
            }
          });
        })
      );

      dispatch(conversationsLoadedAction(conversations));
    });
  };

  const createConversation = async ({ description, title }) => {
    const collectionRef = collection(
      db,
      `users/${user.userID}/cd_conversations`
    );

    const defDoc = {};

    defDoc.title = title;
    defDoc.description = description;
    defDoc.userID = user.userID;
    defDoc.deleted = false;
    defDoc.sortOrder = 1;
    defDoc.createdAt = serverTimestamp();
    defDoc.updatedAt = serverTimestamp();
    delete defDoc.id;

    const docRef = await addDoc(collectionRef, defDoc);
    await setDoc(
      doc(db, `users/${user.userID}/cd_conversations`, docRef.id),
      { id: docRef.id },
      { merge: true }
    );

    return defDoc.id;
  };

  const addParticipants = async ({ conversation, teamMembers, teamName }) => {
    await Promise.all(
      teamMembers.map(async teamMember => {
        await addParticipant({
          conversation,
          teamMember,
          teamName
        });
      })
    );

    // Touch the conversation to force a snapshot update
    await setDoc(
      doc(db, `users/${user.userID}/cd_conversations`, conversation.id),
      {
        updatedAt: serverTimestamp()
      },
      {
        merge: true
      }
    );
  };

  const addParticipant = async ({
    conversation,
    teamMember,
    teamName,
    notes = '',
    status = 'invited'
  }) => {
    const collectionRef = collection(
      db,
      `users/${user.userID}/cd_conversations/${conversation.id}/cd_conversation_participants`
    );

    const defDoc = {};

    if (teamMember.teamMemberUserID) {
      defDoc.userID = teamMember.teamMemberUserID; // participants userID if they are already reg'd
    }
    defDoc.teamMemberId = teamMember.id;
    defDoc.userDynamicId = '';
    defDoc.conversationId = conversation.id;
    defDoc.status = status;
    defDoc.notes = notes;
    defDoc.deleted = false;
    defDoc.sortOrder = 1;
    defDoc.createdAt = serverTimestamp();
    defDoc.updatedAt = serverTimestamp();
    delete defDoc.id;

    const docRef = await addDoc(collectionRef, defDoc);

    const { invitationId } = await createInvitation({
      teamId: teamMember.teamId,
      teamMemberId: teamMember.id,
      teamName,
      conversationId: conversation.id,
      conversationTitle: conversation.title,
      recipientEmail: teamMember.email,
      recipientName: `${teamMember.firstName} ${teamMember.lastName}`,
      isExistingTeamMember: true
    });

    await setDoc(
      doc(
        db,
        `users/${user.userID}/cd_conversations/${conversation.id}/cd_conversation_participants`,
        docRef.id
      ),
      { id: docRef.id, invitationId },
      { merge: true }
    );
  };

  const removeParticipant = async ({ conversationId, participantId }) => {
    setDoc(
      doc(
        db,
        `users/${user.userID}/cd_conversations/${conversationId}/cd_conversation_participants`,
        participantId
      ),
      {
        deleted: true,
        updatedAt: serverTimestamp()
      },
      { merge: true }
    );

    // Touch the conversation to force a snapshot update
    await setDoc(
      doc(db, `users/${user.userID}/cd_conversations`, conversationId),
      {
        updatedAt: serverTimestamp()
      },
      { merge: true }
    );
  };

  const deleteConversation = async conversationId => {
    setDoc(
      doc(db, `users/${user.userID}/cd_conversations`, conversationId),
      {
        deleted: true,
        updatedAt: serverTimestamp()
      },
      { merge: true }
    );

    await revokeInvitations({ conversationId });
  };

  const updateConversation = async ({ title, description, conversationId }) => {
    setDoc(
      doc(db, `users/${user.userID}/cd_conversations`, conversationId),
      {
        title,
        description,
        updatedAt: serverTimestamp()
      },
      { merge: true }
    );
  };

  return {
    conversations,
    addParticipant,
    createConversation,
    deleteConversation,
    removeParticipant,
    updateConversation,
    addParticipants
  };
};

export default useConversations;
