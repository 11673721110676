import React from 'react';
import Assessment from '../../components/Assessment';
import Layout from '../../scaffolding/Layout';

const ShortTest = () => {
  return (
    <Layout>
      <Assessment />
    </Layout>
  );
};

export default ShortTest;
