import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const Button = props => {
  const {
    text,
    outline,
    green = false,
    grey = false,
    style = {},
    path = '#',
    minWidth = 200,
    icon,
    fontNormal,
    onClick
  } = props;

  const buttonStyle = {
    ...style,
    minWidth
  };

  let classes = '';
  if (outline) {
    if (green) {
      classes =
        'text-cd-green bg-cd-white hover:bg-cd-green hover:text-white border-cd-green';
    } else if (grey) {
      classes =
        'text-white bg-gray-500 hover:bg-cd-green hover:text-white border-cd-gray-500';
    } else {
      classes =
        'text-cd-red bg-cd-white hover:bg-cd-red hover:text-white border-cd-red';
    }
  } else {
    if (green) {
      classes = 'text-white bg-cd-green hover:bg-white hover:text-cd-green';
    } else if (grey) {
      classes =
        'text-white bg-gray-500 hover:bg-cd-green hover:text-white border-cd-gray-500';
    } else {
      classes =
        'text-white bg-cd-red hover:bg-white hover:text-cd-red border-cd-red';
    }
  }

  return (
    <Link
      to={path}
      type="button"
      style={buttonStyle}
      onClick={onClick}
      className={clsx(
        'my-4 text-center font-sans justify-center inline-flex items-center px-4 py-3 text-sm rounded-md shadow-sm border',
        fontNormal ? '' : 'font-bold',
        classes
      )}>
      {icon ? <img src={icon} alt="" className="pr-2" width={30} /> : null}
      {text}
    </Link>
  );
};

export default Button;
