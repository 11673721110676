/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

const AddParticipantsToConversationModal = props => {
  const INVITE_STATUS = {
    NONE: '',
    ADDING: 'ADDING',
    ADDED: 'ADDED'
  };

  const { open, setOpen, onAddParticipants, conversation } = props;

  const { teams } = useSelector(state => state.Teams);
  const [selectedTeam, setSelectedTeam] = useState();
  const [participants, setParticipants] = useState([]);
  const [status, setStatus] = useState(INVITE_STATUS.NONE);

  const onTeamSelected = teamId => {
    const team = teams.find(t => t.id === teamId);
    setSelectedTeam(team);
    setParticipants(team.members);
  };

  const onTeamMemberCheckChange = teamMemberId => {
    const people = [...participants];
    const index = people.findIndex(p => p.id === teamMemberId);
    people[index].checked = !people[index].checked;
    setParticipants(people);
  };

  const addParticipants = async () => {
    setStatus(INVITE_STATUS.ADDING);
    await onAddParticipants(
      participants.filter(p => p.checked),
      selectedTeam.name
    );
    setStatus(INVITE_STATUS.ADDED);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left min-w-[85%]">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-gray-900 font-sans font-bold">
                      {status === INVITE_STATUS.NONE ? (
                        <span>Add Teams Member(s) To Conversation</span>
                      ) : (
                        <span>Nice work! Conversation Invites Sent</span>
                      )}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-md text-gray-700">
                        {status === INVITE_STATUS.NONE ? (
                          <span>
                            Select a Team, tick the members you want to invite
                            and we will send them an invite to take the test for
                            the conversation titled <b>{conversation.title}</b>
                          </span>
                        ) : (
                          <span>
                            Invitation emails have been sent to all of the team
                            members you selected to take the Communicator
                            Dynamics® test for the conversation{' '}
                            <b>{conversation.title}</b>
                          </span>
                        )}
                      </p>
                    </div>
                    {status === INVITE_STATUS.NONE ? (
                      <fieldset className="px-4 mt-4">
                        <select
                          id="location"
                          name="location"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          defaultValue=""
                          onChange={e => onTeamSelected(e.target.value)}>
                          <option value="">Select a Team</option>
                          {teams?.map((team, index) => (
                            <option key={index} value={team.id}>
                              {team.name}
                            </option>
                          ))}
                        </select>

                        <div className="h-64 overflow-y-auto mt-4 pr-6 divide-y divide-gray-200 border-b border-t border-gray-200">
                          <>
                            {selectedTeam?.members
                              .filter(
                                t =>
                                  !conversation.participants.some(
                                    p => p.teamMemberId === t.id
                                  )
                              )
                              .map(teamMember => (
                                <div
                                  key={teamMember.id}
                                  className="relative flex items-start py-4">
                                  <div className="min-w-0 flex-1 text-sm leading-6">
                                    <label
                                      htmlFor={`person-${teamMember.id}`}
                                      className="select-none font-medium text-gray-900">
                                      {teamMember.firstName}{' '}
                                      {teamMember.lastName}
                                    </label>
                                  </div>
                                  <div className="ml-3 flex h-6 items-center">
                                    <input
                                      id={`person-${teamMember.id}`}
                                      name={`person-${teamMember.id}`}
                                      defaultValue={
                                        participants.find(
                                          p => p.id === teamMember.id
                                        )?.checked || false
                                      }
                                      onChange={e => {
                                        onTeamMemberCheckChange(teamMember.id);
                                      }}
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                  </div>
                                </div>
                              ))}
                          </>
                          {selectedTeam?.members.filter(
                            t =>
                              !conversation.participants.some(
                                p => p.teamMemberId === t.id
                              )
                          ).length === 0 ? (
                            <div className="text-gray-700 text-center pt-6">
                              {!selectedTeam?.members ||
                              selectedTeam.members.length === 0 ? (
                                <span>
                                  There are no members in the{' '}
                                  {selectedTeam.name} team yet. Add some first.
                                </span>
                              ) : (
                                <span>
                                  All {selectedTeam.name} team members have
                                  already been invited
                                </span>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </fieldset>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 mr-3 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  disabled={status === INVITE_STATUS.ADDING}
                  className={clsx(
                    'bg-cd-green cursor-pointer hover:bg-gray-500',
                    'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white hover:bg-green-700 sm:ml-3 sm:w-auto sm:text-sm'
                  )}
                  onClick={() => {
                    if (status === INVITE_STATUS.NONE) {
                      addParticipants();
                    } else {
                      setOpen(false);
                    }
                  }}>
                  <svg
                    className={clsx(
                      'hidden -ml-1 mr-3 h-5 w-5 text-white',
                      status === INVITE_STATUS.ADDING
                        ? 'animate-spin block'
                        : ''
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  {status === INVITE_STATUS.NONE ? (
                    <span>Invite to Conversation</span>
                  ) : (
                    <span>Close</span>
                  )}
                </button>
                {status !== INVITE_STATUS.ADDED ? (
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}>
                    Cancel
                  </button>
                ) : null}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddParticipantsToConversationModal;
