import React from 'react';
import ContentWidget from '../../../components/ContentWidget';
import videoPlaceholderSmall from '../../../assets/videoplaceholder-sm.png';
import Button from '../../../components/Button';

const NoRecentDynamic = () => {
  return (
    <div className="flex flex-col">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 items-center lg:mx-0 lg:max-w-none lg:grid-cols-2 mb-4">
        <div className="bg-white rounded-lg p-12 flex mb-4">
          <div className="mr-4">
            <img
              src={videoPlaceholderSmall}
              alt=""
              className="mr-2"
              width={183}
            />
          </div>
          <div className="flex items-center w-3/5 font-merriweather font-bold text-2xl">
            Discover Your Team’s Communicator Dynamics for a Conversation that
            matters to you all.
          </div>
        </div>
      </div>
      <div className="mt-2">
        <ContentWidget title="Get Started" className="bg-cd-red">
          <div className="mr-2">
            <Button
              path="/mini-test"
              text="Take the Mini-Test"
              className="mr-2"
            />
          </div>
          <Button path="/full-test" text="Take the Full-Test" />
        </ContentWidget>
      </div>
    </div>
  );
};

export default NoRecentDynamic;
