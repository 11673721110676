import React from 'react';
import ContentWidget from '../../../components/ContentWidget';
import iconDirector from '../../../assets/icons/icon-director.png';
import Button from '../../../components/Button';
import analyticsIcon from '../../../assets/icons/analytics.svg';
import useDynamics from '../../../hooks/dynamics';

const LatestDynamic = () => {
  const { latestDynamic: dynamic } = useDynamics();

  if (!dynamic) {
    return null;
  }

  return (
    <ContentWidget title="Your Latest Dynamic" image={analyticsIcon}>
      <div className="flex flex-col justify-center items-center">
        <div className="text-md font-sans p-4 text-center">
          Congratulations you discovered that when you talk to{' '}
          <b>{dynamic.with}</b> about <b>{dynamic.subject}</b> that you are the{' '}
          <b>{dynamic.archetype}</b>
        </div>
        <img src={iconDirector} className="h-20 w-20 my-2" alt="" />
        <Button
          outline
          path="/dynamics"
          text={`Discover More About The ${dynamic.archetype}`}
        />
      </div>
    </ContentWidget>
  );
};

export default LatestDynamic;
