import React from 'react';
import * as SolidIcons from '@heroicons/react/24/solid';
import * as OutlineIcons from '@heroicons/react/24/outline';

/*
const HeroIconNames = [
  'AcademicCapIcon',
  'AdjustmentsIcon',
  'AnnotationIcon',
  'ArchiveIcon',
  'ArrowCircleDownIcon',
  'ArrowCircleLeftIcon',
  'ArrowCircleRightIcon',
  'ArrowCircleUpIcon',
  'ArrowDownIcon',
  'ArrowLeftIcon',
  'ArrowNarrowDownIcon',
  'ArrowNarrowLeftIcon',
  'ArrowNarrowRightIcon',
  'ArrowNarrowUpIcon',
  'ArrowRightIcon',
  'ArrowSmDownIcon',
  'ArrowSmLeftIcon',
  'ArrowSmRightIcon',
  'ArrowSmUpIcon',
  'ArrowUpIcon',
  'ArrowsExpandIcon',
  'AtSymbolIcon',
  'BackspaceIcon',
  'BadgeCheckIcon',
  'BanIcon',
  'BellIcon',
  'BookOpenIcon',
  'BeakerIcon',
  'BellIcon',
  'BookmarkAltIcon',
  'BookmarkIcon',
  'BriefcaseIcon',
  'CakeIcon',
  'CalculatorIcon',
  'CalendarIcon',
  'CameraIcon',
  'CashIcon',
  'ChartBarIcon',
  'ChartPieIcon',
  'ChartSquareBarIcon',
  'ChatAlt2Icon',
  'ChatAltIcon',
  'ChatIcon',
  'CheckCircleIcon',
  'CheckIcon',
  'ChevronDoubleDownIcon',
  'ChevronDoubleLeftIcon',
  'ChevronDoubleRightIcon',
  'ChevronDoubleUpIcon',
  'ChevronDownIcon',
  'ChevronLeftIcon',
  'ChevronRightIcon',
  'ChevronUpIcon',
  'ChipIcon',
  'ClipboardCheckIcon',
  'ClipboardIcon',
  'ClipboardListIcon',
  'ClipboardIcon',
  'CloudArrowUpTrayIcon',
  'CloudIcon',
  'CodeBracketIcon',
  'CogIcon',
  'CollectionIcon',
  'ColorSwatchIcon',
  'CreditCardIcon',
  'CubeTransparentIcon',
  'CubeIcon',
  'CurrencyBangladeshiIcon',
  'CurrencyDollarIcon',
  'CurrencyEuroIcon',
  'CurrencyPoundIcon',
  'CurrencyRupeeIcon',
  'CurrencyYenIcon',
  'CursorClickIcon',
  'CircleStackIcon',
  'DesktopComputerIcon',
  'DeviceMobileIcon',
  'DeviceTabletIcon',
  'DocumentAddIcon',
  'DocumentArrowDownTrayIcon',
  'DocumentDocumentDuplicateIcon',
  'DocumentRemoveIcon',
  'DocumentReportIcon',
  'DocumentMagnifyingGlassIcon',
  'DocumentTextIcon',
  'DocumentIcon',
  'DotsCircleHorizontalIcon',
  'DotsHorizontalIcon',
  'DotsVerticalIcon',
  'ArrowDownTrayIcon',
  'DocumentDuplicateIcon',
  'EmojiHappyIcon',
  'EmojiSadIcon',
  'ExclamationCircleIcon',
  'ExclamationCircleIcon',
  'ExternalLinkIcon',
  'EyeOffIcon',
  'EyeIcon',
  'FastForwardIcon',
  'FilmIcon',
  'FunnelIcon',
  'FingerPrintIcon',
  'FireIcon',
  'FlagIcon',
  'FolderAddIcon',
  'FolderArrowDownTrayIcon',
  'FolderOpenIcon',
  'FolderRemoveIcon',
  'FolderIcon',
  'GiftIcon',
  'GlobeAltIcon',
  'GlobeIcon',
  'HandIcon',
  'HashtagIcon',
  'HeartIcon',
  'HomeIcon',
  'IdentificationIcon',
  'InboXMarkIcon',
  'InformationCircleIcon',
  'KeyIcon',
  'LibraryIcon',
  'LightBulbIcon',
  'LightningBoltIcon',
  'LinkIcon',
  'LocationMarkerIcon',
  'LockClosedIcon',
  'LockOpenIcon',
  'LoginIcon',
  'LogoutIcon',
  'MailOpenIcon',
  'MailIcon',
  'MapIcon',
  'MenuAlt1Icon',
  'MenuAlt2Icon',
  'MenuIcon',
  'MicrophoneIcon',
  'MinusCircleIcon',
  'MinusSmIcon',
  'MinusIcon',
  'MoonIcon',
  'MusicNoteIcon',
  'NewspaperIcon',
  'OfficeBuildingIcon',
  'PaperAirplaneIcon',
  'PaperClipIcon',
  'PauseIcon',
  'PencilAltIcon',
  'PencilIcon',
  'PhoneIncomingIcon',
  'PhoneMissedCallIcon',
  'PhoneOutgoingIcon',
  'PhoneIcon',
  'PhotographIcon',
  'PlayIcon',
  'PlusCircleIcon',
  'PlusSmIcon',
  'PlusIcon',
  'PresentationChartBarIcon',
  'PresentationChartLineIcon',
  'PrinterIcon',
  'PuzzleIcon',
  'QrCodeBracketIcon',
  'QuestionMarkCircleIcon',
  'ReceiptRefundIcon',
  'ReceiptTaXMarkIcon',
  'ArrowsRightLeftIcon',
  'RewindIcon',
  'RssIcon',
  'FolderIcon',
  'SaveIcon',
  'ScaleIcon',
  'ScissorsIcon',
  'SearchCircleIcon',
  'MagnifyingGlassIcon',
  'ArrowDownIcon',
  'ServerIcon',
  'ShareIcon',
  'ShieldCheckIcon',
  'ShieldExclamationCircleIcon',
  'ShoppingBagIcon',
  'ShoppingCartIcon',
  'SortAscendingIcon',
  'SortDescendingIcon',
  'SparklesIcon',
  'SpeakerphoneIcon',
  'StarIcon',
  'StatusOfflineIcon',
  'StatusOnlineIcon',
  'StopIcon',
  'SunIcon',
  'SupportIcon',
  'SwitchHorizontalIcon',
  'SwitchVerticalIcon',
  'TableIcon',
  'TagIcon',
  'TemplateIcon',
  'CommandLineIcon',
  'ThumbDownIcon',
  'ThumbUpIcon',
  'TicketIcon',
  'TranslateIcon',
  'TrashIcon',
  'TrendingDownIcon',
  'TrendingUpIcon',
  'TruckIcon',
  'ArrowUpTrayIcon',
  'UserAddIcon',
  'UserCircleIcon',
  'UserGroupIcon',
  'UserRemoveIcon',
  'UserIcon',
  'UsersIcon',
  'VariableIcon',
  'VideoCameraIcon',
  'ViewColumnsIcon',
  'ViewGridAddIcon',
  'ViewGridIcon',
  'ViewListIcon',
  'VolumeOffIcon',
  'VolumeUpIcon',
  'WifiIcon',
  'XCircleIcon',
  'XMarkIcon',
  'ZoomInIcon',
  'ZoomOutIcon',
  'ThumbUpIcon',
  'ReceiptTaXMarkIcon',
  'RefreshIcon',
  'MenuAlt3Icon',
  'MenuAlt4Icon',
  'EyeOffIcon',
  'ClockIcon',
  'CloudArrowDownTrayIcon',
  'BookOpenIcon',
  'ExternalLinkIcon'
];
*/

const HeroIconNames = Object.keys(SolidIcons);

const HeroIcon = props => {
  const { icon, color, size, outline = false, className, style } = props;

  const { ...icons } = outline ? OutlineIcons : SolidIcons;

  const Icon = icons[icon];

  if (!Icon) {
    return <>Invalid Icon {icon}</>;
  }
  const classes = [`h-${size ? size : 6}`, `w-${size ? size : 6}`];

  if (className) {
    className.split(' ').forEach(item => {
      classes.push(item);
    });
  }

  if (!color || color.length === 0) {
    classes.push('text-black');
  } else if (color.substring(0, 1) !== '#') {
    classes.push(color);
  }

  return (
    <Icon className={classes.join(' ')} style={{ color: color, ...style }} />
  );
};

export { HeroIcon, HeroIconNames };
