import React, { useState, Fragment } from 'react';
import AddParticipantsToConversationModal from './AddParticipantsToConversationModal';
import DeleteConversationModal from '../DeleteConversationModal';
import useConversations from '../../../../hooks/conversations';

import Participants from './Participants';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { AtSymbolIcon, XCircleIcon } from '@heroicons/react/24/solid';
import EditConversationModal from './EditConversationModal';
import { PencilIcon } from '@heroicons/react/24/outline';
import TeamMemberDynamic from '../../TeamMemberDynamic';
import useTeams from '../../../../hooks/teams';
import DeleteParticipantModal from './DeleteParticipantModal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Conversation = ({ conversation, onDeleted }) => {
  const {
    deleteConversation,
    updateConversation,
    removeParticipant,
    addParticipants
  } = useConversations();

  const { teams } = useTeams();

  const [showAddParticipantsModal, setShowAddParticipantsModal] = useState(
    false
  );
  const [showDeleteParticipantModal, setShowDeleteParticipantModal] = useState(
    false
  );

  const [selectedParticipant, setSelectedParticipant] = useState();
  const [participantToRemove, setParticipantToRemove] = useState();

  const [
    showDeleteConversationModal,
    setShowDeleteConversationModal
  ] = useState(false);
  const [showEditConversationModal, setShowEditConversationModal] = useState(
    false
  );

  const [tabs, setTabs] = useState([
    { name: 'Participants', href: '#', current: true },
    { name: 'Insights', href: '#', current: false }
  ]);

  const onTabSelected = tabIndex => {
    const newTabs = tabs.map((tab, index) => {
      return {
        ...tab,
        current: tabIndex === index
      };
    });
    setTabs(newTabs);
  };

  const onAddParticipantsToConversation = async (teamMembers, teamName) => {
    await addParticipants({ conversation, teamMembers, teamName });
  };

  const onParticipantDeleted = async participant => {
    await removeParticipant({
      conversationId: conversation.id,
      participantId: participant.id
    });
    setShowDeleteParticipantModal(false);
    setParticipantToRemove(undefined);
  };

  const onConversationDeleted = async () => {
    setShowDeleteConversationModal(false);
    await deleteConversation(conversation.id);
    onDeleted();
  };

  const onConversationEdited = async ({ title, description }) => {
    setShowEditConversationModal(false);
    await updateConversation({
      title,
      description,
      conversationId: conversation.id
    });
  };

  const displayParticipantDynamic = participant => {
    const team = teams.find(t =>
      t.members.some(m => m.id === participant.teamMemberId)
    );
    if (team) {
      setSelectedParticipant({
        team,
        participant,
        teamMember: team.members.find(m => m.id === participant.teamMemberId)
      });
    } else {
      setSelectedParticipant(undefined);
    }
  };

  /*if (selectedParticipant) {
    return (
      <TeamMemberDynamic {...selectedParticipant} conversation={conversation} />
    );
  }*/

  return (
    <>
      {showEditConversationModal ? (
        <EditConversationModal
          open={showEditConversationModal}
          setOpen={setShowEditConversationModal}
          onConversationEdited={onConversationEdited}
          conversation={conversation}
        />
      ) : null}
      {showAddParticipantsModal ? (
        <AddParticipantsToConversationModal
          open={showAddParticipantsModal}
          setOpen={setShowAddParticipantsModal}
          onAddParticipants={onAddParticipantsToConversation}
          conversation={conversation}
        />
      ) : null}
      {showDeleteConversationModal ? (
        <DeleteConversationModal
          conversation={conversation}
          setOpen={setShowDeleteConversationModal}
          open={showDeleteConversationModal}
          onConversationDeleted={onConversationDeleted}
        />
      ) : null}
      {showDeleteParticipantModal ? (
        <DeleteParticipantModal
          participant={participantToRemove}
          conversation={conversation}
          setOpen={setShowDeleteParticipantModal}
          open={showDeleteParticipantModal}
          onParticipantDeleted={onParticipantDeleted}
        />
      ) : null}
      <div className="bg-white px-4 py-5 sm:px-6 w-full">
        <div className="flex space-x-3 border-b pb-4">
          <div className="min-w-0 flex-1 items-center ">
            <div className="font-merriweather text-2xl font-bold text-cd-navy items-center">
              {conversation.title}
            </div>
            <div className="flex text-gray-500">
              <span>
                Teams: Sales, Marketing. Participants: 20. Completed: 82%
              </span>
            </div>
          </div>

          <div className="flex flex-shrink-0 self-center ">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-cd-navy hover:text-gray-600">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            setShowEditConversationModal(true);
                          }}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-cd-navy',
                            'flex px-4 py-2 text-sm'
                          )}>
                          <PencilIcon
                            className="mr-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span>Edit</span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            setShowAddParticipantsModal(true);
                          }}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'flex px-4 py-2 text-sm'
                          )}>
                          <AtSymbolIcon
                            className="mr-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span>Invite Participants</span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            setShowDeleteConversationModal(true);
                          }}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-cd-navy',
                            'flex px-4 py-2 text-sm'
                          )}>
                          <XCircleIcon
                            className="mr-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span>Delete</span>
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        {!selectedParticipant ? (
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cd-green-500 sm:text-sm"
                defaultValue={tabs.find(tab => tab.current).name}>
                {tabs.map(tab => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab, index) => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      onClick={() => onTabSelected(index)}
                      className={classNames(
                        tab.current
                          ? 'border-cd-green text-green-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                      )}
                      aria-current={tab.current ? 'page' : undefined}>
                      {tab.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        ) : null}

        {selectedParticipant ? (
          <TeamMemberDynamic
            onClose={e => {
              e.preventDefault();
              setSelectedParticipant(undefined);
            }}
            {...selectedParticipant}
            conversation={conversation}
          />
        ) : (
          <>
            {tabs[0].current ? (
              <Participants
                onParticipantSelected={displayParticipantDynamic}
                onParticipantRemoved={participant => {
                  setParticipantToRemove(participant);
                  setShowDeleteParticipantModal(true);
                }}
                conversation={conversation}
              />
            ) : (
              <div>Insights to go here</div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Conversation;
