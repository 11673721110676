import React from 'react';
import facetOnIcon from '../../../../../assets/icons/facet-on.svg';
import facetOffIcon from '../../../../../assets/icons/facet-off.svg';
import Button from '../../../../../components/Button';

const FacetGoalOption = ({ option, selected, dynamic }) => {
  return (
    <div className="flex flex-col items-center flex-grow cursor-pointer z-10 group pt-20 -mt-15">
      <img
        src={selected ? facetOnIcon : facetOffIcon}
        alt=""
        className="hover:animation-pulse"
      />
      <span className="text-sm -mt-6 text-navy hover:font-bold">
        {option.name}
      </span>
      {selected ? (
        <Button grey minWidth={50} text="Develop" />
      ) : (
        <Button minWidth={50} text="Become" />
      )}
    </div>
  );
};

export default FacetGoalOption;
