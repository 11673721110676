import { useState, useEffect } from 'react';
import { db, serverTimestamp } from '../../firebase';

import { collection, onSnapshot, query, setDoc, doc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { userInvitationsLoaded } from '../../redux/actions/users';

const useUser = () => {
  const user = useSelector(state => state.User);

  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const { invitations } = useSelector(state => state.User);

  useEffect(() => {
    //let unsubUserInvitations = () => {};
    if (user?.validUser && !loaded) {
      // unsubUserInvitations = watchUserInvitations();
      watchUserInvitations();
      setLoaded(true);
    }
    return () => {
      try {
        // unsubUserInvitations();
      } catch {}
    };
  }, [user]);

  const watchUserInvitations = () => {
    const q = query(collection(db, `users/${user.userID}/cd_user_invitations`));

    return onSnapshot(q, async snapshot => {
      const userInvitations = [];

      snapshot.forEach(async doc => {
        const invitation = doc.data();
        invitation.participants = [];
        invitation.id = doc.id;
        if (!invitation?.deleted) {
          userInvitations.push(invitation);
        }
      });

      dispatch(userInvitationsLoaded(userInvitations));
    });
  };

  const updateUser = async ({ firstName, lastName }) => {
    await setDoc(
      doc(db, 'users', user.userID),
      {
        firstName,
        lastName,
        updatedAt: serverTimestamp()
      },
      { merge: true }
    );
  };

  const recordLastLogin = async userId => {
    await setDoc(
      doc(db, 'users', userId),
      {
        lastLogin: serverTimestamp()
      },
      { merge: true }
    );
  };

  const createAccount = async ({ firstName, lastName, email, userID }) => {
    await setDoc(doc(db, 'users', userID), {
      firstName,
      lastName,
      email,
      role: 'standard',
      userID,
      updatedAt: serverTimestamp()
    });
  };

  return {
    createAccount,
    updateUser,
    userInvitations: invitations,
    recordLastLogin
  };
};

export default useUser;
