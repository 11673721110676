import React from 'react';
import ContentWidget from '../../../components/ContentWidget';
import Button from '../../../components/Button';
import BookIcon from '../../../assets/icons/book.svg';

const TakeTheFullTest = () => {
  return (
    <ContentWidget title="Take The Full Test" image={BookIcon}>
      <div className="flex flex-col justify-center items-center">
        <div className="text-md font-sans p-4 text-center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </div>
        <Button path="/full-test" text="START NOW" />
      </div>
    </ContentWidget>
  );
};

export default TakeTheFullTest;
