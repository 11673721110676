import React, { useEffect, useState } from 'react';
import CompanyLogo from '../../assets/company-logo.png';

import {
  createUserWithEmailAndPassword,
  sendEmailVerification
} from 'firebase/auth';
import { auth } from '../../firebase';
import Version from '../../version';
import { Link, redirect } from 'react-router-dom';
import {
  isValidEmail,
  isValidFirstOrLastName,
  isValidPassword
} from '../../utils/Validation';
import useUser from '../../hooks/user';
import useAutomations from '../../hooks/automations';

const Register = () => {
  const { createAccount } = useUser();
  const { startNewUserAutomations } = useAutomations();
  const [registerError, setRegisterError] = useState();
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [creds, setCreds] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    if (verificationEmailSent) {
      redirect('/account-pending');
    }
  }, [verificationEmailSent]);

  const validateInformation = () => {
    if (!isValidFirstOrLastName(creds.firstName || '')) {
      setRegisterError({ message: 'Please enter a valid first name' });
      return false;
    } else if (!isValidFirstOrLastName(creds.lastName || '')) {
      setRegisterError({ message: 'Please enter a valid last name' });
      return false;
    } else if (
      !isValidEmail(creds.email || '') ||
      creds.email.indexOf('softio.co.uk') > -1 ||
      creds.email.indexOf('communicatordynamics.com') > -1 ||
      creds.email.indexOf('communicatordynamics.co.uk') > -1
    ) {
      setRegisterError({ message: 'Please enter a valid email' });
      return false;
    } else if (!isValidPassword(creds.password || '')) {
      setRegisterError({
        message:
          'Please enter a valid password with at least one uppercase letter, one number and one special character.'
      });
      return false;
    } else if (creds.confirmPassword !== creds.password) {
      setRegisterError({
        message: 'Password and confirm password do not match'
      });
      return false;
    }

    return true;
  };

  const registerUser = async e => {
    e.preventDefault();

    if (!validateInformation()) {
      return;
    }
    setRegisterError(undefined);
    createUserWithEmailAndPassword(auth, creds.email, creds.password)
      .then(async userCredential => {
        await createAccount({
          firstName: creds.firstName,
          lastName: creds.lastName,
          email: creds.email,
          userID: userCredential.user.uid
        });

        sendEmailVerification(auth.currentUser).then(() => {
          console.log('email verficiation sent');
          setVerificationEmailSent(true);
        });

        await startNewUserAutomations(userCredential.user.uid);
      })
      .catch(error => setRegisterError(error));
  };

  return (
    <>
      <div
        className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8"
        data-testid="login">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-20 w-auto mb-6" src={CompanyLogo} alt="" />
          <h2 className="mt-8 text-center text-3xl font-extrabold text-gray-900 my-8 font-merriweather">
            Register for an account
          </h2>
          <p className="mt-2 text-center text-md text-gray-600">
            Pop your details in below to access Communicator Dynamics®. No
            credit card required.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {registerError && (
              <div className="text-red-700 mb-2">{registerError.message}</div>
            )}
            {creds.password !== creds.confirmPassword && (
              <div className="text-red-700 mb-2">
                Password and confirm passwords must match
              </div>
            )}
            <form className="space-y-6" action="#" method="POST">
              <div data-testid="firstNameField">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
                <div className="mt-1 flex flex-grow">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={e =>
                      setCreds({ ...creds, firstName: e.target.value })
                    }
                    autoComplete="off"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div data-testid="lastNameField">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <div className="mt-1">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={e => {
                      setCreds({ ...creds, lastName: e.target.value });
                      setRegisterError(undefined);
                    }}
                    autoComplete="off"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div data-testid="emailField">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={e => {
                      setCreds({ ...creds, email: e.target.value });
                      setRegisterError(undefined);
                    }}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div data-testid="passwordField">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    data-testid="passwordInput"
                    id="password"
                    name="password"
                    type="password"
                    onChange={e => {
                      setCreds({ ...creds, password: e.target.value });
                      setRegisterError(undefined);
                    }}
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div data-testid="confirmPasswordField">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <div className="mt-1">
                  <input
                    data-testid="confirmPasswordInput"
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    onChange={e => {
                      setCreds({ ...creds, confirmPassword: e.target.value });
                      setRegisterError(undefined);
                    }}
                    autoComplete="none"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center min-w-full text-center">
                <button
                  data-testid="loginButton"
                  type="submit"
                  onClick={e => registerUser(e)}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cd-red hover:bg-white hover:text-cd-red border-2 border-cd-red">
                  Register
                </button>
              </div>

              <div className="flex items-center justify-between text-center">
                <Link
                  to="/login"
                  className="text-sm min-w-full self-center text-cd-navy hover:text-cd-red">
                  Already Registered? Login
                </Link>
              </div>
            </form>
          </div>
          <Version />
        </div>
      </div>
    </>
  );
};
export default Register;
