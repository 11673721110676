import { USER_ACTIONS } from '../../actions/users';

const initialState = {
  validUser: false,
  invitations: []
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS.USER_INVITATIONS_LOADED: {
      const newState = { ...state };
      newState.invitations = action.payload;
      return newState;
    }
    case USER_ACTIONS.USER_EMAIL_VERIFIED: {
      const newState = { ...state };
      if (newState.session) {
        newState.session.emailVerified = true;
      }
      return newState;
    }
    case USER_ACTIONS.USER_LOADED:
      return {
        ...state,
        invalidUser: false,
        validUser: true,
        ...action.payload
      };
    case USER_ACTIONS.INVALID_USER:
      return {
        ...initialState,
        invalidUser: true,
        validUser: false
      };
    case USER_ACTIONS.USER_LOGOUT:
      return {
        ...initialState,
        invalidUser: state.invalidUser,
        validUser: false
      };
    case USER_ACTIONS.USER_STATS_LOADED:
      return {
        ...state,
        stats: action.payload
      };
    default:
      return state;
  }
};

export default UserReducer;
