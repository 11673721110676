import React from 'react';
import FacetGoalOption from '../FacetGoalOption';
import facetLine from '../../../../../assets/icons/facet-line.svg';
import FacetGoalText from '../FacetGoalText';

const FacetGoal = ({ facet, dynamic }) => {
  return (
    <div className="flex flex-col items-center border-b-2 pb-8 lg:pb-0 lg:border-0 border-white ">
      <div className="lg:hidden font-merriweather text-xl text-cd-navy font-bold mt-8 absolute">
        {facet.name}
      </div>
      <div className="mx-auto grid min-w-full grid-cols-6 gap-x-8 gap-y-8 lg:mx-0 items-center my-4 lg:mt-0">
        <div className="hidden lg:block font-merriweather text-xl text-cd-navy font-bold">
          {facet.name}
        </div>
        <div
          className="flex min-w-full flex-grow lg:col-span-5 col-span-6"
          style={{
            backgroundImage: `url(${facetLine})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}>
          {facet.documents.map((document, index) => (
            <FacetGoalOption
              key={index}
              option={document}
              dynamic={dynamic}
              selected={document.id === facet.value.id}
            />
          ))}
        </div>
      </div>
      <FacetGoalText facet={facet} dynamic={dynamic} />
    </div>
  );
};

export default FacetGoal;
