import React from 'react';
import ReactPlayer from 'react-player';
import ContentWidget from '../../../../components/ContentWidget';
import videoExplainerPlaceholder from '../../../../assets/video-placeholder.png';

const DynamicVideo = ({ title, subtitle, videoUrl }) => {
  return (
    <div className="border rounded border-gray-300">
      <ContentWidget
        title={title}
        subtitle={subtitle}
        titleFont="sans"
        titleFontWeight="normal">
        {videoUrl ? (
          <ReactPlayer url={videoUrl} />
        ) : (
          <img
            src={videoExplainerPlaceholder}
            className="mt-6 opacity-50 flex"
            alt=""
          />
        )}
      </ContentWidget>
    </div>
  );
};

export default DynamicVideo;
