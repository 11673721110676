import React from 'react';
import Layout from '../../scaffolding/Layout';
const people = [
  {
    name: 'Initial Assessment',
    keyworker: 'Edith Muran',
    date: '01 Mar 2023'
  },
  {
    name: 'Setting Goals',
    keyworker: 'Edith Muran',
    date: '9 Mar 2023'
  },
  {
    name: 'Access to Benefits',
    keyworker: 'Edith Muran',
    date: '21 Mar 2023'
  }
  // More people...
];

export default function Example() {
  return (
    <Layout>
      <div className="px-4 sm:px-6 lg:px-8 bg-white min-w-full">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Previous Sessions
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all your previous keyworker sessions.
            </p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Keyworker
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {people.map(person => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        {person.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.keyworker}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.date}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
