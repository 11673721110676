import React from 'react';

const FacetGoalText = ({ facet, dynamic }) => {
  let goalType = dynamic[`${facet.field}GoalType`];

  if (!['transform', 'develop'].some(t => t === goalType)) {
    return null;
  }

  return (
    <div className="text-center min-w-full">
      I have set a goal to:{' '}
      <b>{goalType === 'transform' ? 'BECOME' : 'DEVELOP'}</b> the{' '}
      <b>{dynamic[`${facet.field}GoalFacetName`]}</b>
    </div>
  );
};

export default FacetGoalText;
