import React from 'react';
import ContentWidget from '../../../components/ContentWidget';
import goalsIcon from '../../../assets/icons/communication-goals-large.svg';
import Button from '../../../components/Button';

const SetGoals = () => {
  return (
    <ContentWidget title="Set Your Communication Goals">
      <div className="flex grid grid-cols-1 lg:grid-cols-2 p-8 text-md items-center">
        <img className="h-32 w-32 -mt-1 mb-2 mx-auto" src={goalsIcon} alt="" />
        <p className="text-center mt-3 lg:mt-0 lg:text-left">
          It’s fun and fascinating to discover your Communicator Dynamic for a
          particular conversation. Now take the next step and decide how you
          want to develop your skills again and this is that.
        </p>
      </div>
      <div className="flex w-full justify-center">
        <Button path="/goals" text="SET COMMUNICATION GOALS" />
      </div>
    </ContentWidget>
  );
};

export default SetGoals;
