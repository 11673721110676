import React from 'react';
import celebrationImage from '../../../../assets/celebration.png';
import yellowStar from '../../../../assets/icons/yellow-star.svg';
import greenLine from '../../../../assets/icons/green-line.svg';

const DynamicResultHeader = ({ dynamic }) => {
  if (!dynamic) {
    return null;
  }

  return (
    <div className="rounded-t-lg bg-white">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-5 pt-6 lg:pt-4 items-center">
        <div className="flex flex-col col-span-3 px-6 lg:pl-16 text-center">
          <span className="font-merriweather text-cd-red font-bold text-1xl lg:text-2xl">
            Your latest Communicator Dynamic® Is:
          </span>
          <div className="flex items-center text-center justify-center">
            <img src={greenLine} alt="" className="hidden lg:block" />
            <img src={yellowStar} width={30} alt="" className="mx-2" />
            <span className="font-merriweather text-cd-green font-bold my-7 text-2xl lg:text-4xl">
              The {dynamic.archetype}
            </span>
            <img src={yellowStar} width={30} alt="" className="mx-2" />
            <img src={greenLine} alt="" className="hidden lg:block" />
          </div>
          <div className="lg:px-48 text-md">
            This is the Communicator Dynamic® you discovered for a conversation
            about {dynamic.subject} with {dynamic.with} on <br />
            <span className="text-cd-red">{dynamic.date}</span>
          </div>
        </div>
        <div
          className="flex items-center lg:items-left justify-center lg:justify-left col-span-2 overflow-hidden"
          style={{ maxHeight: 310 }}>
          <img src={celebrationImage} width={350} alt="" />
        </div>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-5 items-center lg:rounded-b-lg bg-cd-red">
        <div className="flex flex-col col-span-3 lg:pl-16 text-center text-white py-5">
          Identify each aspect of your {dynamic.archetype} Communicator Dynamic®
          below.
        </div>
      </div>
    </div>
  );
};

export default DynamicResultHeader;
