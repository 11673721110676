import React from 'react';

const TeamDetails = props => {
  const {
    /*firstName,
    lastName,
    email,
    jobTitle,
    department,
    notes,
    id,*/
    teamId
  } = props;

  return <div>team details {teamId}</div>;
};

export default TeamDetails;
