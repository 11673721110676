import React from 'react';

const Billing = () => {
  return (
    <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      Billing
    </div>
  );
};

export default Billing;
