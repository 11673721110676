import { UserIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/solid';
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebase';

const logout = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      console.log('logged out');
    })
    .catch(error => {
      console.log(error);
    });
};

const UserNav = [
  {
    path: '/profile',
    title: 'My Profile',
    description: 'Manage My Profile',
    icon: UserIcon,
    id: 'profile'
  },
  {
    path: '/billing',
    title: 'Billing',
    description: 'Billing',
    icon: UserIcon,
    id: 'billing'
  },
  {
    path: '/login',
    title: 'Logout',
    description: 'Logout',
    icon: ArrowLeftOnRectangleIcon,
    id: 'logout',
    onClick: logout
  }
];

export default UserNav;
