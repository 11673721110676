import React from 'react';
import FacetOption from '../FacetOption';
import facetLine from '../../../assets/icons/facet-line.svg';

const Facet = ({ facet }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="lg:hidden font-merriweather text-xl text-cd-navy font-bold mt-8">
        {facet.name}
      </div>
      <div className="mx-auto grid min-w-full grid-cols-6 gap-x-8 gap-y-8 lg:mx-0 items-center">
        <div className="hidden lg:block font-merriweather text-xl text-cd-navy font-bold">
          {facet.name}
        </div>
        <div
          className="flex min-w-full flex-grow lg:col-span-5 col-span-6"
          style={{
            backgroundImage: `url(${facetLine})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}>
          {facet.documents.map((document, index) => (
            <FacetOption
              key={index}
              option={document}
              selected={document.id === facet.value.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Facet;
