import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import useTeams from '../../../../../hooks/teams';
import defaultAvatar from '../../../../../assets/default-avatar.png';
import { Menu, Transition } from '@headlessui/react';
import {
  XCircleIcon,
  AtSymbolIcon,
  EllipsisVerticalIcon
} from '@heroicons/react/24/solid';

import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import useInvitations from '../../../../../hooks/invitations';
import InvitationReSentModal from '../InvitationReSentModal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Participants = ({
  conversation,
  onParticipantSelected,
  onParticipantRemoved
}) => {
  const { teams } = useTeams();
  const { resendInvitation } = useInvitations();

  const [showInvitationReSentModal, setShowInvitationReSentModal] = useState(
    false
  );

  const getProfilePhoto = photoUrl => {
    if (photoUrl && Array.isArray(photoUrl) && photoUrl.length > 0) {
      return photoUrl[0].thumb;
    } else {
      return defaultAvatar;
    }
  };

  const getFriendlyStatus = status => {
    switch (status) {
      case 'invited': {
        return 'Invitation Sent';
      }
      case 'accepted': {
        return 'Invitation Accepted';
      }
      case 'inprogress': {
        return 'Test Started';
      }
      case 'completed': {
        return 'Test Completed';
      }
      case 'rejected': {
        return 'Rejected Invite';
      }
      default:
        return 'Unknown status';
    }
  };

  const reissueInvitation = async participant => {
    await resendInvitation(participant.invitationId);
    setShowInvitationReSentModal(true);
  };

  return (
    <div className="min-w-full flex flex-col">
      {showInvitationReSentModal ? (
        <InvitationReSentModal
          open={showInvitationReSentModal}
          setOpen={setShowInvitationReSentModal}
        />
      ) : null}
      <p className="text-sm text-gray-600 mt-4">
        You can add an entire team or an individual team member as a participant
        in this conversation. This will send them an invite to complete a
        Communicator Dynamics® test for this particular conversation. Click a
        participant below to view their Communicator Dynamic® for this
        conversation.
      </p>
      <ul className="mt-8 mx-auto grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-4 xl:grid-cols-4">
        {conversation?.participants.map((participant, index) => {
          const participantTeam = teams.find(t =>
            t.members.some(m => m.id === participant.teamMemberId)
          );
          if (!participantTeam) {
            return null;
          }

          const teamMember = participantTeam.members.find(
            m => m.id === participant.teamMemberId
          );
          return (
            <li
              key={index}
              className={clsx(
                ' rounded-lg p-2 border-gray-300 border',
                participant.status === 'completed'
                  ? 'hover:border-cd-green cursor-pointer'
                  : 'cursor-default'
              )}
              onClick={() => {
                if (participant.status === 'completed') {
                  onParticipantSelected(participant);
                }
              }}>
              <div className="flex flex-shrink-0 self-center justify-end">
                <Menu
                  as="div"
                  className="relative inline-block text-left self-end">
                  <div>
                    <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-cd-navy hover:text-gray-600">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {['invited', 'revoked', 'sending', 'rejected'].some(
                          p => p === participant.status
                        ) ? (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                onClick={async e => {
                                  e.preventDefault();
                                  reissueInvitation(participant);
                                }}
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'flex px-4 py-2 text-sm'
                                )}>
                                <AtSymbolIcon
                                  className="mr-3 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span>Re-Send Invite</span>
                              </a>
                            )}
                          </Menu.Item>
                        ) : (
                          ''
                        )}
                        {participant.status === 'completed' ? (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                onClick={e => {
                                  e.preventDefault();
                                }}
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-cd-navy',
                                  'flex px-4 py-2 text-sm'
                                )}>
                                <MagnifyingGlassCircleIcon
                                  className="mr-3 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span>View Communicator Dynamic®</span>
                              </a>
                            )}
                          </Menu.Item>
                        ) : (
                          ''
                        )}
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                onParticipantRemoved(participant);
                              }}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-cd-navy',
                                'flex px-4 py-2 text-sm'
                              )}>
                              <XCircleIcon
                                className="mr-3 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span>Remove from Conversation</span>
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <img
                className="mx-auto h-24 w-24 rounded-full"
                src={getProfilePhoto(teamMember.photoUrl)}
                alt=""
              />
              <h3 className="mt-6 text-base font-bold  font-merriweather text-gray-900">
                {teamMember.firstName} {teamMember.lastName}
              </h3>
              <p className="text-sm text-gray-600 mt-1">
                {teamMember.jobTitle} in {participantTeam.name}
              </p>
              <p className="text-sm text-gray-600 mt-1">
                ({getFriendlyStatus(participant.status)})
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Participants;
