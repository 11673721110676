import React from 'react';
import ContentWidget from '../../../components/ContentWidget';
import videoPlaceholder from '../../../assets/video.png';

const InviteShareCollaborate = () => {
  return (
    <ContentWidget title="Invite, Share, Collaborate" width={600} height={460}>
      <p className="text-md pt-3">
        Discover how you can invite others to take the test and overlay their
        results with yours as well as sharing your results and collaborating
        with others.
      </p>
      <img src={videoPlaceholder} className="mt-3" alt="" />
    </ContentWidget>
  );
};

export default InviteShareCollaborate;
