import React, { useState } from 'react';
import Layout from '../../scaffolding/Layout';
import TeamsNavigation from './TeamsNavigation';
import useTeams from '../../hooks/teams';
import DiscoverBanner from './DiscoverBanner';
import Conversations from './Conversations';
import TeamDetails from './TeamDetails';
import DeleteTeamMemberModal from './DeleteTeamMemberModal';

const TeamDynamics = () => {
  const { teams, removeTeamMember } = useTeams();
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [showDeleteTeamMemberModal, setShowDeleteTeamMemberModal] = useState(
    false
  );

  const showTeam = team => {
    setSelectedTeamMember(undefined);
    setSelectedTeam(team);
  };

  const showTeamMember = ({ teamMember, team }) => {
    setSelectedTeamMember({ teamMember, team });
    setSelectedTeam(undefined);
  };

  const onCloseTeamMember = () => {
    setSelectedTeamMember(undefined);
  };

  const onDeleteTeamMember = () => {
    setShowDeleteTeamMemberModal(true);
  };

  const onTeamMemberDeleted = async () => {
    setShowDeleteTeamMemberModal(false);
    await removeTeamMember({
      memberId: selectedTeamMember.teamMember.id,
      teamId: selectedTeamMember.team.id
    });
    setSelectedTeamMember(undefined);
    setSelectedTeam(undefined);
  };

  return (
    <Layout
      stickyBg="bg-cd-navy"
      stickyLeft={
        <TeamsNavigation
          teams={teams}
          onTeamSelected={showTeam}
          selectedTeamMember={selectedTeamMember}
          onTeamMemberSelected={showTeamMember}
        />
      }>
      {showDeleteTeamMemberModal ? (
        <DeleteTeamMemberModal
          teamMember={selectedTeamMember.teamMember}
          team={selectedTeamMember.team}
          onTeamMemberDeleted={onTeamMemberDeleted}
          setOpen={setShowDeleteTeamMemberModal}
          open={showDeleteTeamMemberModal}
        />
      ) : null}
      <div className="flex flex-col mx-6">
        <DiscoverBanner />
        {selectedTeam ? <TeamDetails team={selectedTeam} /> : null}
        <Conversations
          selectedTeamMember={selectedTeamMember}
          onCloseTeamMember={onCloseTeamMember}
          onDeleteTeamMember={onDeleteTeamMember}
        />
      </div>
    </Layout>
  );
};

export default TeamDynamics;
