import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { friendlyTimestamp } from '../../../../utils/Timestamp';
import calendarIcon from '../../../../assets/icons/calendar.svg';
import calendarIconWhite from '../../../../assets/icons/calendar-white.svg';
import Button from '../../../../components/Button';
import AddConversationModal from '../AddConversationModal';
import useConversations from '../../../../hooks/conversations';

const ConversationList = ({
  selectedConversation,
  setSelectedConversation,
  conversations
}) => {
  const { createConversation } = useConversations();
  const [showAddConversationModal, setShowAddConversationModal] = useState(
    false
  );

  const addNewConversation = async ({ title, description }) => {
    setShowAddConversationModal(false);
    await createConversation({ title, description });
  };

  useEffect(() => {
    if (!selectedConversation && conversations?.length > 0) {
      setSelectedConversation(conversations[0]);
    }
  }, [conversations]);

  return (
    <div className="min-w-full border-l-2 border-gray-200">
      {showAddConversationModal ? (
        <AddConversationModal
          open={showAddConversationModal}
          setOpen={setShowAddConversationModal}
          onConversationCreated={addNewConversation}
        />
      ) : null}
      <div className="flex flex-col mx-auto pt-4 flex pl-4">
        <div className="font-merriweather text-2xl font-bold ">
          Conversations
        </div>
        <div className="font-sans font-md">Conversations across your team.</div>
        <div className="flex mr-1 text-center items-center min-w-full">
          <Button
            red
            outline
            text="Add Conversation"
            onClick={() => setShowAddConversationModal(true)}
          />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center pt-4 mt-2 overflow-hidden items-center min-w-full">
        {conversations.map((conversation, index) => (
          <div
            className={clsx(
              selectedConversation?.id === conversation.id
                ? 'bg-cd-green text-white'
                : 'bg-white',
              'flex hover:bg-cd-green hover:text-white w-full px-4 py-10 cursor-pointer'
            )}
            onClick={() => setSelectedConversation(conversation)}
            key={index}>
            <div className="flex flex-col">
              <span className="font-sans text-md">{conversation.title}</span>
              <div className="flex mt-1">
                {selectedConversation?.id === conversation.id ? (
                  <img
                    src={calendarIconWhite}
                    alt=""
                    width={10}
                    className="mr-2"
                  />
                ) : (
                  <img src={calendarIcon} alt="" width={10} className="mr-2" />
                )}
                <span className="text-xs text-gray-400">
                  {friendlyTimestamp(conversation.updatedAt, true)}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConversationList;
