import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator
} from 'firebase/functions';
import { useDispatch, useSelector } from 'react-redux';

import { dynamicsLoadedAction } from '../../redux/actions/dynamics';
import { useEffect, useState } from 'react';

import { db } from '../../firebase';
import { collection, onSnapshot, query } from 'firebase/firestore';

/*
    Hook which provides a list of all user dynamics tests
*/
const useDynamics = () => {
  const functions = getFunctions();
  const dispatch = useDispatch();

  const user = useSelector(state => state.User);
  const [latestDynamic, setLatestDynamic] = useState();
  const [dynamicLinks, setDynamicLinks] = useState();
  const dynamics = useSelector(state => state.Dynamics.dynamics);

  useEffect(() => {
    let unsubUserDynamicLinks = () => {};
    if (user?.validUser) {
      unsubUserDynamicLinks = watchUserDynamicLinks();
    }
    return () => {
      try {
        unsubUserDynamicLinks();
      } catch {}
    };
  }, [user]);

  useEffect(() => {
    setLatestDynamic(dynamics.length > 0 ? dynamics[0] : undefined);
  }, [dynamics]);

  useEffect(() => {
    if (dynamicLinks?.length > 0) {
      loadDynamics();
    }
  }, [dynamicLinks]);

  if (process.env.NODE_ENV === 'development') {
    //console.log('useCloud running against emulators on 5001...');
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }

  const loadDynamics = async () => {
    const comdynListFunction = httpsCallable(functions, 'cdList');
    const dynamics = await comdynListFunction({});

    dispatch(dynamicsLoadedAction(dynamics.data));
    return dynamics.data;
  };

  const watchUserDynamicLinks = () => {
    const q = query(
      collection(db, `users/${user.userID}/cd_user_dynamic_links`)
    );

    return onSnapshot(q, async snapshot => {
      const links = [];

      snapshot.forEach(doc => {
        const dataItem = doc.data();
        dataItem.id = doc.id;
        links.push(dataItem);
      });

      // TODO: check if anything has really changed otherwise we might be reloading everything unncessarily
      setDynamicLinks(links);

      /*snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          console.log('New city: ', change.doc.data());
        }
        if (change.type === 'modified') {
          console.log('Modified city: ', change.doc.data());
        }
        if (change.type === 'removed') {
          console.log('Removed city: ', change.doc.data());
        }
      });*/
    });
  };

  return {
    latestDynamic,
    loadDynamics,
    dynamics
  };
};

export default useDynamics;
