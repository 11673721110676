const CONVERSATION_ACTIONS = {
  CONVERSATIONS_LOADED: 'CONVERSATIONS_LOADED',
  MEMBER_INVITED: 'MEMBER_INVITED'
};

const conversationsLoadedAction = conversations => {
  return {
    type: CONVERSATION_ACTIONS.CONVERSATIONS_LOADED,
    payload: conversations
  };
};

const teamMemberInvitedAction = invite => {
  return {
    type: CONVERSATION_ACTIONS.MEMBER_INVITED,
    payload: invite
  };
};

export {
  CONVERSATION_ACTIONS,
  conversationsLoadedAction,
  teamMemberInvitedAction
};
