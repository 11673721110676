import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CompanyLogo from '../../assets/company-logo.png';

import {
  sendEmailVerification,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { auth } from '../../firebase';
import Version from '../../version';
import { Link } from 'react-router-dom';
import useAutomations from '../../hooks/automations';

const Login = () => {
  const [loginError, setLoginError] = useState();

  const { startNewUserAutomations } = useAutomations();
  const [creds, setCreds] = useState({ email: '', password: '' });

  const user = useSelector(state => state.User);

  useEffect(() => {
    if (Object.keys(user).length > 1) {
      //dispatch({ type: 'USER_LOGOUT', payload: {} });
    }
  }, [user]);

  const signIn = async e => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, creds.email, creds.password)
      .then(async userCredential => {
        if (!userCredential.user?.emailVerified) {
          sendEmailVerification(auth.currentUser).catch(error => {
            setLoginError(error);
          });
        }
        await startNewUserAutomations(userCredential.user.uid);
      })
      .catch(error => {
        setLoginError(error);
      });
  };

  return (
    <div
      className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8"
      data-testid="login">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-20 w-auto mb-6" src={CompanyLogo} alt="" />
        <h2 className="mt-8 text-center text-3xl font-extrabold text-gray-900 mt-8 font-merriweather">
          Login
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {loginError && (
            <div className="text-red-700 mb-2">{loginError.message}</div>
          )}
          {user?.invalidUser && (
            <div className="text-red-700 mb-2">
              This account is not authorised to login.
            </div>
          )}
          <form className="space-y-6" action="#" method="POST">
            <div data-testid="emailField">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={e => setCreds({ ...creds, email: e.target.value })}
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div data-testid="passwordField">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  data-testid="passwordInput"
                  id="password"
                  name="password"
                  type="password"
                  onChange={e =>
                    setCreds({ ...creds, password: e.target.value })
                  }
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  data-testid="rememberMeCheckbox"
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-cd-green border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <Link
                  data-testid="lnkForgotPassword"
                  to="/recover"
                  className="font-medium text-cd-navy hover:text-cd-red">
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div className="flex flex-col items-center ">
              <button
                data-testid="loginButton"
                type="submit"
                onClick={e => signIn(e)}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:text-cd-red bg-cd-red hover:bg-white border-1 hover:border-cd-red">
                Sign in
              </button>

              <Link
                data-testid="lnkForgotPassword"
                to="/register"
                className="text-sm mt-4 text-cd-navy hover:text-cd-red">
                Not registered?
              </Link>
            </div>
          </form>
        </div>
        <Version />
      </div>
    </div>
  );
};
export default Login;
