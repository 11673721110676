import React from 'react';

import Layout from '../../scaffolding/Layout';

import LatestDynamic from './LatestDynamic';
import TakeTheFullTest from './TakeTheFullTest';
import UnlockEverything from './UnlockEverything';
import LatestCourses from './LatestCourses';
import NextWorkshop from './NextWorkshop';
import SetGoals from './SetGoals';
import RecentTests from './RecentTests';
import GetBookOrAppBanner from './GetBookOrAppBanner';
import InviteShareCollaborate from './InviteShareCollaborate';
import FindACoach from './FindACoach';

import ContentWidget from '../../components/ContentWidget';
import videoExplainerPlaceholder from '../../assets/video-placeholder.png';
import Invitations from './Invitations';

const Dashboard = () => {
  return (
    <Layout
      sticky={
        <div
          className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none items-center"
          style={{ width: 360 }}>
          <LatestCourses />
          <NextWorkshop />
        </div>
      }>
      <Invitations />
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 items-center lg:mx-0 lg:max-w-none lg:grid-cols-1 mb-4">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 items-center">
          <LatestDynamic />
          <TakeTheFullTest />
          <UnlockEverything />
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center">
          <ContentWidget title="What is Communicator Dynamics®?">
            <img
              src={videoExplainerPlaceholder}
              className="mt-6 opacity-50 flex"
              alt=""
            />
          </ContentWidget>
          <SetGoals />
        </div>

        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-1 items-center">
          <GetBookOrAppBanner />
        </div>
        <div className="mx-auto w-full grid grid-cols-1 gap-x-8 gap-y-8 items-center">
          <RecentTests />
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center">
          <InviteShareCollaborate />
          <FindACoach />
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
